import React, { useEffect, useState } from "react";
import api from "../helpers/api";
import useSessionStorage from "../helpers/useSessionStorage";

const DataFetcher = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [books, setBooks] = useSessionStorage("books");
    const [news, setNews] = useSessionStorage("news");
    const [blogs, setBlogs] = useSessionStorage("blogs");
    const [reviews, setReviews] = useSessionStorage("reviews");
    const [about, setAbout] = useSessionStorage("about");
    const [characters, setCharacters] = useSessionStorage("characters");
    const [races, setRaces] = useSessionStorage("races");
    const [words, setWords] = useSessionStorage("words");

    const fetchData = async () => {
        try {
            const [booksData, newsData, blogsData, reviewsData, aboutData, charactersData, racesData, wordsData] = await Promise.all([
                api.getAll("books"),
                api.getAll("news"),
                api.getAll("blog"),
                api.getAll("bookReviews"),
                api.getAll("about"),
                api.getAll("characters"),
                api.getAll("races"),
                api.getAll("pronunciation")
            ]);

            setBooks(JSON.stringify(booksData));
            setNews(JSON.stringify(newsData));
            setBlogs(JSON.stringify(blogsData));
            setReviews(JSON.stringify(reviewsData));
            setAbout(JSON.stringify(aboutData));
            setCharacters(JSON.stringify(charactersData));
            setRaces(JSON.stringify(racesData));
            setWords(JSON.stringify(wordsData));

            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Add your animated loading indicator here later
    }

    return <>{children}</>;
};

export default DataFetcher;
