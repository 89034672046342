import React, { useState, useEffect } from "react";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import "../../css/NewsForm.css";

const NewsForm = ({ initialData = null }) => {
    const [news, setNews] = useSessionStorage("news");
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        image: ""
    });
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                title: initialData.title || "",
                content: initialData.content || "",
                image: initialData.image || ""
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => ({
            ...data,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const d = new Date();
        const day = d.getDate();
        const month = d.getMonth();
        const year = d.getFullYear();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        const milliseconds = d.getMilliseconds();
        const date = `${year}-${month+1}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

        try {
            const dataToSubmit = {
                title: formData.title,
                content: formData.content,
                image: formData.image,
                date: !initialData ? date : initialData.date
            };
            if (isUpdating) {
                await api.updateByTitleOrName("news", initialData.title, dataToSubmit);
            } else {
                await api.createWithFour("news", formData.title, formData.content, date, formData.image);
            }
            window.sessionStorage.removeItem("news");
            const updatedNews = await api.getAll("news");
            setNews(JSON.stringify(updatedNews));
            window.location.href = "/news";
        } catch (error) {
            console.error("API Request Failed: ", error);
        }
    };

    return (
        <div className="newsFormParent">
            <h2>{isUpdating ? `Update News Titled ${initialData.title}` : "Add New News"}</h2>
            <form className="newsForm" onSubmit={handleSubmit}>
                <div className="newsFormDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs newsTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            required={isUpdating}
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea newsContent"
                            placeholder="Content"
                            name="content"
                            id="content"
                            required={isUpdating}
                            value={formData.content}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs newsImage"
                            placeholder="Image Link"
                            name="image"
                            id="image"
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewsForm;
