import React, { useState, useEffect } from "react";
import api from "../../helpers/api";
import "../../css/AboutForm.css"; // Assuming the combined form has its own CSS file
import useSessionStorage from "../../helpers/useSessionStorage";

const AboutForm = ({ initialData = null }) => {
    const [about, setAbout] = useSessionStorage("about");
    const [formData, setFormData] = useState({
        name: "",
        shortBio: "",
        longBio: "",
        image: ""
    });
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name || "",
                shortBio: initialData.short_bio || "",
                longBio: initialData.long_bio || "",
                image: initialData.image || ""
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => ({
            ...data,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Transform the data to match the schema
            const dataToSubmit = {
                name: formData.name,
                short_bio: formData.shortBio,
                long_bio: formData.longBio,
                image: formData.image
            };
            if (isUpdating) {
                await api.updateAbout(initialData.name, dataToSubmit);
            } else {
                await api.createAbout(dataToSubmit);
            }
            window.sessionStorage.removeItem("about");
            const updatedAbout = await api.getAll("about");
            setAbout(JSON.stringify(updatedAbout));
            window.location.href = "/about";
        } catch (error) {
            console.error("API Request Failed: ", error);
        }
    };

    return (
        <div className="aboutFormParent">
            <h2>{isUpdating ? `Update About Info` : "Add New About Info"}</h2>
            <form className="aboutForm" onSubmit={handleSubmit}>
                <div className="aboutFormDiv">
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs aboutName"
                            placeholder="Name"
                            name="name"
                            id="name"
                            required
                            value={formData.name}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs aboutName"
                            placeholder="Name"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        {!initialData ? <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea aboutShortBio"
                            placeholder="Short Bio"
                            name="shortBio"
                            id="shortBio"
                            required
                            value={formData.shortBio}
                            onChange={handleChange}
                        /> : <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea aboutShortBio"
                            placeholder="Short Bio"
                            name="shortBio"
                            id="shortBio"
                            value={formData.shortBio}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        {!initialData ? <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea aboutLongBio"
                            placeholder="Long Bio"
                            name="longBio"
                            id="longBio"
                            required
                            value={formData.longBio}
                            onChange={handleChange}
                        /> : <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea aboutLongBio"
                            placeholder="Long Bio"
                            name="longBio"
                            id="longBio"
                            value={formData.longBio}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs aboutImage"
                            placeholder="Image"
                            name="image"
                            id="image"
                            required
                            value={formData.image}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs aboutImage"
                            placeholder="Image"
                            name="image"
                            id="image"
                            value={formData.image}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AboutForm;
