import React, { useEffect, useState } from "react";
import jwt from "jwt-decode";
import Book from "./Book";
import "../css/BooksPage.css";
import useLocalStorage from "../helpers/useLocalStorage";
import api from "../helpers/api";
import useSessionStorage from "../helpers/useSessionStorage";

const BooksPage = () => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const [books, setBooks] = useState([]);
    const [bks, setBks] = useSessionStorage("books");

    const setResources = async () => {
        if (bks !== "undefined" && bks !== undefined) {
            setBooks(JSON.parse(bks));
        } else {
            const fetchedBooks = await api.getAll("books");
            setBks(JSON.stringify(fetchedBooks));
            setBooks(fetchedBooks);
        }
    };

    useEffect(() => {
        setResources();
    }, []);

    const ignoreWords = ["and", "the", "a"];

    const normalizeSeriesName = (series) => {
        if (!series) return "Other";
        const words = series.split(" ");
        if (ignoreWords.includes(words[0].toLowerCase())) {
            return words.slice(1).join(" ");
        }
        return series;
    };

    const groupBooksBySeries = (books) => {
        return books.reduce((acc, book) => {
            const series = book.series || "Other";
            const normalizedSeries = normalizeSeriesName(series);
            if (!acc[normalizedSeries]) {
                acc[normalizedSeries] = {
                    displayName: series,
                    books: []
                };
            }
            acc[normalizedSeries].books.push(book);
            return acc;
        }, {});
    };

    const groupedBooks = groupBooksBySeries(books);

    const sortedSeries = Object.keys(groupedBooks).sort((a, b) => a.localeCompare(b));

    return (
        <div className="bookPage">
            <h1 className="booksPageHeading">Books Available and Coming Soon</h1>
            {admin ? <p><a href="/books/new" className="newContentBtn" key="newContentBtn">Add New Book</a></p> : null}
            {sortedSeries.map(normalizedSeries => (
                <div key={normalizedSeries} className="bookSeriesSection">
                    <h2 className="bookSeriesHeading">{groupedBooks[normalizedSeries].displayName}</h2>
                    {groupedBooks[normalizedSeries].books.map(book => (
                        <Book title={book.title} key={`book-${book.series}-${book.title}`} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default BooksPage;
