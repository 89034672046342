import React, {useState} from "react";
import api from "../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "../css/User.css";

const User = ({user}) => {
    const [isActive, setIsActive] = useState(false);
    const [emailBtnIsActive, setEmailBtnIsActive] = useState(false);
    const [firstNameBtnIsActive, setFirstNameBtnIsActive] = useState(false);
    const [lastNameBtnIsActive, setLastNameBtnIsActive] = useState(false);
    const [securityQuestionBtnIsActive, setSecurityQuestionBtnIsActive] = useState(false);
    const url = window.location.href.split("/");
    const isAdmin = user.admin ? faUserPlus : faUser;
    const [icon, setIcon] = useState(isAdmin);
    const endUrl = url[url.length - 1];
    const userOrAdmin = endUrl === "users" ? "admin" : "user";

    const INITIAL_STATE = {
        email:"",
        firstName:"",
        lastName:"",
        securityQuestion:"",
        securityAnswer:""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(endUrl === "users"){
            const userAdmin = user.admin ? false : true;
            user.admin = userAdmin;
            userAdmin ? setIcon(faUserPlus) : setIcon(faUser);
            await api.updateUser(user.username, user.password, user.email, user.firstName, user.lastName, user.securityQuestion, user.securityAnswer, user.birthDate, userAdmin);
        } else {
            const email = formData.email !== "" ? formData.email : user.email;
            const firstName = formData.firstName !== "" ? formData.firstName : user.firstName;
            const lastName = formData.lastName !== "" ? formData.lastName : user.lastName;
            const securityQuestion = formData.securityQuestion !== "" ? formData.securityQuestion : user.securityQuestion;
            const securityAnswer = formData.securityAnswer !== "" ? formData.securityAnswer : user.securityAnswer;
            await api.updateUser(user.username, user.password, email, firstName, lastName, securityQuestion, securityAnswer, user.birthDate, user.admin);
            setFormData(INITIAL_STATE);
            window.location.href=`/users/${user.username}`;
        }
    }
    const handleDelete = async (e) => {
        e.preventDefault();
        await api.deleteByOneIdentifier("users", user.username);
        window.location.href = "/users";
    }
    return (
        <div className={`${userOrAdmin}UserDiv`}>
            <div className={`${userOrAdmin}UsernameDiv`}>
                <h1 className={`${userOrAdmin}Username`}>{user.username}</h1>
                {endUrl === "users" ? <div className="adminShowHideBtnDiv"><button className="adminShowMoreBtn showHideBtn" onClick={() => setIsActive(current => !current)}>{isActive ? "Hide Info":"Show Info"}</button></div> : null}
            </div>
            <div id={`userInfo`} className={`${endUrl === "users" && !isActive ? "hidden" : ""} ${userOrAdmin}UserInfo`}>
                <p className={`${userOrAdmin}Email`}><strong>Email:</strong> {user.email} 
                    {endUrl === user.username ? <button className="userEditShowHide" onClick={() => setEmailBtnIsActive(current => !current)}>{emailBtnIsActive ? "Cancel":"Edit"}</button> : null}
                    {emailBtnIsActive ?
                            <input type="text"
                                   className="userUpdateFormInput"
                                   placeholder="Email Update"
                                   name="email"
                                   id="email"
                                   key="email"
                                   value={formData.email}
                                   onChange={handleChange} /> : null}
                    {emailBtnIsActive ? <button className="submitBtn" onClick={handleSubmit}>Submit</button> : null}
                </p>
                <p className={`${userOrAdmin}FirstName`}><strong>First Name:</strong> {user.firstName}
                    {endUrl === user.username ? <button className="userEditShowHide" onClick={() => setFirstNameBtnIsActive(current => !current)}>{firstNameBtnIsActive ? "Cancel":"Edit"}</button> : null}
                    {firstNameBtnIsActive ? 
                            <input type="text"
                                className="userUpdateFormInput"
                                placeholder="First Name Update"
                                name="firstName"
                                id="firstName"
                                key="firstName"
                                value={formData.firstName}
                                onChange={handleChange} /> : null}
                    {firstNameBtnIsActive ? <button className="submitBtn" onClick={handleSubmit}>Submit</button> : null}
                </p>
                <p className={`${userOrAdmin}LastName`}><strong>Last Name:</strong> {user.lastName}
                    {endUrl === user.username ? <button className="userEditShowHide" onClick={() => setLastNameBtnIsActive(current => !current)}>{lastNameBtnIsActive ? "Cancel":"Edit"}</button> : null}
                    {lastNameBtnIsActive ?
                            <input type="text"
                                   className="userUpdateFormInput"
                                   placeholder="Last Name Update"
                                   name="lastName"
                                   id="lastName"
                                   key="lastName"
                                   value={formData.lastName}
                                   onChange={handleChange} /> : null}
                    {lastNameBtnIsActive ? <button className="submitBtn" onClick={handleSubmit}>Submit</button> : null}
                </p>
                <p className={`${userOrAdmin}SecurityQuestion`}><strong>Current Security Question:</strong> {user.securityQuestion}
                    {endUrl === user.username ? <button className="userEditShowHide" onClick={() => setSecurityQuestionBtnIsActive(current => !current)}>{securityQuestionBtnIsActive ? "Cancel":"Edit"}</button> : null}
                    {securityQuestionBtnIsActive ? <>
                            <input type="text"
                                   className="userUpdateFormInput"
                                   placeholder="Security Question Update"
                                   name="securityQuestion"
                                   id="securityQuestion"
                                   key="securityQuestion"
                                   value={formData.securityQuestion}
                                   onChange={handleChange} />
                            <input type="password"
                                   className="userUpdateFormInput"
                                   placeholder="Security Answer Update"
                                   name="securityAnswer"
                                   id="securityAnswer"
                                   key="securityAnswer"
                                   value={formData.securityAnswer}
                                   onChange={handleChange} />
                    </> : null}
                    {securityQuestionBtnIsActive ? <button className="submitBtn" onClick={handleSubmit}>Submit</button> : null}
                </p>
                {endUrl === "users" ? <p className="adminStatus"><strong>Admin</strong> <button className="adminBtn" onClick={handleSubmit}><FontAwesomeIcon className="adminIcon" style={{color: `${user.admin ? "springgreen" : "steelblue"}`}} icon={icon} /></button></p> : null}
                <div className="fillerDiv"></div>
                {endUrl === "users" ? <div className="adminDeleteDiv">
                    <a href={`/delete/${user.username}`} className="deleteBtn adminDeleteBtn" onClick={handleDelete}>Delete User</a>
                </div>: null}
            </div>
        </div>
    )

}

export default User;