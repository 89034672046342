const dateConversion = (date) => {
    let month;
    let day;
    let year;
    let hour;
    let minute;
    let splitDate;
    const months = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "Septempter",
        "10": "October",
        "11": "November",
        "12": "December",
        "1":"January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September"
    }
    if(date !== undefined | "undefined"){
        if(date.includes("/") !== false){
            splitDate = date.split("/");
            month = months[splitDate[0]];
            year = splitDate[2];
            day = splitDate[1];
            return `${month} ${day}, ${year}`;
        } else {
            const sliceDate = date.slice(0, 10);
            splitDate = sliceDate.split("-");
            month = months[splitDate[1]];
            year = splitDate[0];
            day = splitDate[2];
            const t = date.slice(11, 16)
            const splitTime = t.split(":");
            hour = splitTime[0];
            minute = splitTime[1];
            const time = `${hour}:${minute}`
            return `${month} ${day}, ${year} ${time}`;
        }
    }
}

export default dateConversion;