import { useState, useEffect } from "react";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";

const CharacterDelete = () => {
    const [characters, setCharacters] = useSessionStorage("characters");
    const [isSet, setIsSet] = useState(false);
    let url = window.location.href.split("/");
    const name = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteCharacter = async (name) => {
            await api.deleteByOneIdentifier("characters", name);
        }
        deleteCharacter(name);
        window.sessionStorage.removeItem("characters");
        const updateCharacters = async () => {
            const temp = [];
            const charRes = await api.getAll("characters");
            for(let i = 0; i < charRes.length; i++){
                temp.push(charRes[i]);
            }
            await setCharacters(JSON.stringify(temp));
            setIsSet(true);
        }
        updateCharacters();        
    } else {
        window.location.href = "/more";
    }    
}

export default CharacterDelete