import React, {useState} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../../helpers/useLocalStorage";
import api from "../../helpers/api";
import "../../css/BookReviewCommentNewForm.css";

const BookReviewCommentNewForm = () => {
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;
    const url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");
    const d = new Date();
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    const milliseconds = d.getMilliseconds();
    const date = `${year}-${month+1}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    const INITIAL_STATE = {
        comment:""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const reviewTitle = `${username}-${title}-${month+1}/${day}/${year}-${hours}:${minutes}:${seconds}.${milliseconds}`
        api.createBookReviewComment(username, reviewTitle, title, formData.comment, date);
        setFormData(INITIAL_STATE);
        window.location.href = `/reviews/${title}`;
    }
    return(
        <div className="newBookReviewCommentParent">
            <h2>Add New Book Review Comment</h2>
            <form className="newBookReviewCommentForm" onSubmit={handleSubmit}>
                <div className="newBookReviewCommentDiv">
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea bookReviewCommentComment"
                            placeholder="Comment"
                            name="comment"
                            id="comment"
                            key="comment"
                            required
                            value={formData.comment}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default BookReviewCommentNewForm;