import { useState } from "react";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";

const NewsDelete = () => {
    const [news, setNews] = useSessionStorage("news");
    const [isSet, setIsSet] = useState(false);
    const url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteNews = async () => {
            await api.deleteByOneIdentifier("news", title);
        }
        deleteNews();
        window.sessionStorage.removeItem("news");
        const updateNews = async () => {
            let temp = [];
            const nwsRes = await api.getAll("news");
            for(let i = 0; i < nwsRes.length; i++){
                temp.push(nwsRes[i]);
            }
            await setNews(JSON.stringify(temp));
            setIsSet(true);
        }
        updateNews();
    } else {
        window.location.href = "/news";
    }
}

export default NewsDelete