import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import BookReviewForm from "../Updated Scripts/BookReviewForm";

const BookReviewUpdateForm = () => {
    const { title } = useParams();
    const [reviews] = useSessionStorage("reviews");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (reviews) {
            const reviewsArray = JSON.parse(reviews);
            const review = reviewsArray.find(r => r.title === title);
            setInitialData(review);
        }
    }, [title, reviews]);

    return (
        <div>
            {initialData ? <BookReviewForm initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default BookReviewUpdateForm;
