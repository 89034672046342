import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import NewsForm from "../Updated Scripts/NewsForm";

const NewsUpdateForm = () => {
    const { title } = useParams();
    const [news] = useSessionStorage("news");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (news) {
            const newsArray = JSON.parse(news);
            const article = newsArray.find(n => n.title === title);
            setInitialData(article);
        }
    }, [title, news]);

    return (
        <div>
            {initialData ? <NewsForm initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default NewsUpdateForm;
