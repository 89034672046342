import { useEffect } from "react";
import api from "../helpers/api";

const UserReviewDelete = () => {
    const url = window.location.href.split("/");
    const id = url[url.length-1]
    const title = url[url.length-2].replaceAll("%20", " ")

    useEffect(() => {
        const deleteUserReview = async (id) => {
            await api.deleteByTwoIdentifier("userReviews", title, id);
        }
        deleteUserReview(id);
    }, []);
    window.location.href = "/books";
}

export default UserReviewDelete;