import React, { useState, useEffect } from "react";
import api from "../../helpers/api";
import "../../css/BlogForm.css";
import useSessionStorage from "../../helpers/useSessionStorage";

const BlogForm = ({ initialData = null }) => {
    const [blogs, setBlogs] = useSessionStorage("blogs");
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        image: ""
    });
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                title: initialData.title || "",
                content: initialData.content || "",
                image: initialData.image || ""
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => ({
            ...data,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const d = new Date();
        const day = d.getDate();
        const month = d.getMonth();
        const year = d.getFullYear();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        const milliseconds = d.getMilliseconds();
        const date = `${year}-${month+1}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        
        try {
            const dataToSubmit = {
                title: formData.title,
                content: formData.content,
                image: formData.image,
                date: date
            };
            if (isUpdating) {
                await api.updateByTitleOrName("blog", initialData.title, dataToSubmit);
            } else {
                await api.createWithFour("blog", formData.title, formData.content, date, formData.image);
            }
            window.sessionStorage.removeItem("blogs");
            const updatedBlogs = await api.getAll("blog");
            setBlogs(JSON.stringify(updatedBlogs));
            window.location.href = "/blog";
        } catch (error) {
            console.error("API Request Failed: ", error);
        }
    };

    return (
        <div className="blogFormParent">
            <h2>{isUpdating ? `Update Blog Titled ${initialData.title}` : "Add New Blog"}</h2>
            <form className="blogForm" onSubmit={handleSubmit}>
                <div className="blogFormDiv">
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs blogTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            required
                            value={formData.title}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs blogTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            value={formData.title}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        {!initialData ? <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea blogContent"
                            placeholder="Content"
                            name="content"
                            id="content"
                            required
                            value={formData.content}
                            onChange={handleChange}
                        /> : <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea blogContent"
                            placeholder="Content"
                            name="content"
                            id="content"
                            value={formData.content}
                            onChange={handleChange}
                        /> }
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs blogImage"
                            placeholder="Image Link"
                            name="image"
                            id="image"
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BlogForm;
