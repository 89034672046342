import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import BookForm from "../Updated Scripts/BookForm";

const BookUpdateForm = () => {
    const { title } = useParams();
    const [books, setBooks] = useSessionStorage("books");
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        const fetchBookData = async () => {
            const storedBooks = JSON.parse(books) || [];
            let bookData = storedBooks.find(bk => bk.title === title);
            if (!bookData) {
                const fetchedBooks = await api.getAll("books");
                bookData = fetchedBooks.find(bk => bk.title === title);
                if (bookData) {
                    setBooks(JSON.stringify(fetchedBooks));
                }
            }
            setInitialData(bookData);
        };
        fetchBookData();
    }, [title, books, setBooks]);

    if (!initialData) {
        return <div>Loading...</div>;
    }

    return <BookForm initialData={initialData} />;
};

export default BookUpdateForm;
