import { useState } from "react";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";

const WordDelete = () => {
    const [words, setWords] = useSessionStorage("words");
    const [isSet, setIsSet] = useState(false);
    let url = window.location.href.split("/");
    const word = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteWord = async (word) => {
            await api.deleteByOneIdentifier("pronunciation", word);
        }
        deleteWord(word);
        window.sessionStorage.removeItem("words");
        const updateWords = async () => {
            const temp = [];
            const wrdRes = await api.getAll("pronunciation");
            for(let i = 0; i < wrdRes.length; i++){
                temp.push(wrdRes[i]);
            }
            await setWords(JSON.stringify(temp));
            setIsSet(true)
        }
        updateWords();
    } else {
        window.location.href = "/more";
    }
}

export default WordDelete