import React from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import "../css/Race.css";

const Race = ({race}) => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    return (
        <div className="raceDiv">
            <div className="raceInfoDiv">
                <h1 className="raceName">{race.name}</h1>
                <h3 className="raceMagic">Magic: {race.magic}</h3>
                <ReactMarkdown remarkPlugins={[gfm, remarkBreaks]} className="raceDetails">{race.details}</ReactMarkdown>
            </div>
            <div className="rightMoreCol">
                {race.image ? <div className="raceImgDiv"><img className="raceImg" src={race.image} alt={`${race.name} symbol`} /></div> : null}                
                {admin ? <div className="infoMgmtDiv">
                    <div className="mgmtBtn">
                        <a href={`/more/update/race/${race.name}`} className="updateBtn" key="updateRaceBtn">Update Race</a>
                    </div>
                    <div className="mgmtBtn">
                        <a href={`/more/delete/race/${race.name}`} className="deleteBtn" key="deleteRaceBtn">Delete Race</a>
                    </div>
                </div>: null}
            </div>
        </div>
    )
}

export default Race;