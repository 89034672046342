import { useState } from "react";
import api from "../helpers/api";
import useSessionStorage from "../helpers/useSessionStorage";

const BookReviewDelete = () => {
    const [reviews, setReviews] = useSessionStorage("reviews");
    const [isSet, setIsSet] = useState(false);
    const url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");

    if(!isSet){
        const deleteBookReview = async (title) => {
            await api.deleteByOneIdentifier("bookReviews", title);
        }
        deleteBookReview(title);
        window.sessionStorage.removeItem("reviews");
        const updateReviews = async () => {
            const temp = [];
            const rvwRes = await api.getAll("bookReviews");
            for(let i = 0; i < rvwRes.length; i++){
                temp.push(rvwRes[i]);
            }
            await setReviews(JSON.stringify(temp));
            setIsSet(true);
        }
        updateReviews();
    } else {
        window.location.href = "/reviews";
    }
}

export default BookReviewDelete