import { useEffect } from "react";
import api from "../helpers/api";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";

const BookReviewCommentDelete = () => {
    let url = window.location.href.split("/");
    const id = url[url.length-1];
    const title = url[url.length-2].replaceAll("%20", " ");
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;

    useEffect(() => {
        const deleteComment = async (id, title, username) => {
            await api.deleteByThreeIdentifiers("bookReviewComments", title, username, id);
        }
        deleteComment(id, title, username);
    }, []);
    window.location.href = `/reviews/${title}`;
}

export default BookReviewCommentDelete;