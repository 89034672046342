import React, {useState, useEffect} from "react";
import api from "../../helpers/api";
import jwt from "jwt-decode";
import useLocalStorage from "../../helpers/useLocalStorage";
import "../../css/UserReviewsUpdateForm.css";

const UserReviewsUpdateForm = () => {
    const [info, setInfo] = useState({});
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;
    const url = window.location.href.split("/");
    const id = url[url.length-1];
    const title = url[url.length-2].replaceAll("%20", " ");
    useEffect(() => {
        const gatherUserReviewInfo = async (title, id) => {
            const res = await api.getByTitleId("userReviews", title, id);

            setInfo(res);
        }
        gatherUserReviewInfo(title, id);
    }, [title, id])
    const INITIAL_STATE = {
        reviewTitle:"",
        review:"",
        contentRating:"",
        flowRating:"",
        editRating: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();    
        const reviewTitle = formData.reviewTitle !== "" ? formData.reviewTitle : info.review_title;
        const review = formData.review !== "" ? formData.review : info.review;
        const contentRating = formData.contentRating !== "" ? parseFloat(formData.contentRating) : info.content_rating;
        const flowRating = formData.flowRating !== "" ? parseFloat(formData.flowRating) : info.flow_rating;
        const editRating = formData.editRating !== "" ? parseFloat(formData.editRating) : info.edit_rating;
        await api.updateUserReview(info.id, info.username, info.book_title, reviewTitle, contentRating, flowRating, editRating, review, info.date);
        setFormData(INITIAL_STATE);
        window.location.href = "/books";
    }
    return (
        <div className="userReviewUpdateParent">
            <h2>Update User Review Titled {info.review_title} by {info.username}</h2>
            <form className="userReviewUpdateForm" onSubmit={handleSubmit}>
                <div className="userReviewUpdateDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs userReviewTitle"
                            placeholder="Title"
                            name="reviewTitle"
                            id="reviewTitle"
                            key="reviewTitle"
                            value={formData.reviewTitle}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea userReviewReview"
                            placeholder="Review"
                            name="review"
                            id="review"
                            key="review"
                            value={formData.review}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"
                            className="formRating userReviewRating"
                            placeholder="Content Rating"
                            min="1"
                            max="5"
                            name="contentRating"
                            id="contentRating"
                            key="contentRating"
                            value={formData.contentRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"                            
                            className="formRating userReviewRating"
                            placeholder="Flow Rating"
                            min="1"
                            max="5"
                            name="flowRating"
                            id="flowRating"
                            key="flowRating"
                            value={formData.flowRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"                            
                            className="formRating userReviewRating"
                            placeholder="Edit Rating"
                            min="1"
                            max="5"
                            name="editRating"
                            id="editRating"
                            key="editRating"
                            value={formData.editRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default UserReviewsUpdateForm;