import React, {useEffect, useState} from "react";
import jwt from "jwt-decode";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import "../css/AboutPage.css";
import useLocalStorage from "../helpers/useLocalStorage";
import useSessionStorage from "../helpers/useSessionStorage";

const AboutPage = () => {
    const about = useSessionStorage("about")
    const [name, setName] = useState("");
    const [longBio, setLongBio] = useState("");
    const [image, setImage] = useState("");
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    useEffect(() => {
        const retrieveAbout = async () => {
            const abt = JSON.parse(about[0]);
            setName(abt.name);
            setLongBio(abt.long_bio);
            setImage(abt.image);
        }
        retrieveAbout();
    }, []);
    
    return(
        <>
            <div className="aboutDiv">
                <h1 className="aboutName">About the Author {name}</h1>
                {image ? <img className="aboutImage leftAboutCol" src={image} alt={`Author-${name}`} /> : null}
                <ReactMarkdown remarkPlugins={[gfm, remarkBreaks]} className="aboutLongBio">{longBio}</ReactMarkdown>
                {admin ? <div className="aboutMgmtBtn"><a className="updateBtn mgmtBtn" href={`/about/update/${name}`}>Update Info</a></div> : null}
            </div>
        </>
    )
}

export default AboutPage;