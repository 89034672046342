//Basics
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//Page Components
import BooksPage from "./BooksPage";
import NewsPage from "./NewsPage";
import BlogPage from "./BlogPage";
import ReviewsPage from "./ReviewsPage";
import AboutPage from "./AboutPage";
import MorePage from "./MorePage";
import UsersPage from "./UsersPage";
import UserPage from "./UserPage";
import Contact from "./Contact";
import HomePage from "./HomePage";
//Individual Components
import BookDelete from "./BookDelete";
import NewsDelete from "./NewsDelete";
import BlogDelete from "./BlogDelete";
import RaceDelete from "./RaceDelete";
import CharacterDelete from "./CharacterDelete";
import WordDelete from "./WordDelete";
import UserReviewDelete from "./UserReviewDelete";
import BlogCommentDelete from "./BlogCommentDelete";
import BookReviewCommentDelete from "./BookReviewCommentDelete";
import Book from "./Book";
import Blog from "./Blog";
import Review from "./Review";
//Form Components
import LoginForm from "./Forms/LoginForm";
import Signout from "./Signout";
import RegisterForm from "./Forms/RegisterForm";
import BookForm from "./Updated Scripts/BookForm";
import BookUpdateForm from "./Forms/BookUpdateForm";
import NewsUpdateForm from "./Forms/NewsUpdateForm";
import BlogUpdateForm from "./Forms/BlogUpdateForm";
import BookReviewUpdateForm from "./Forms/BookReviewUpdateForm";
import BookReviewDelete from "./BookReviewDelete";
import AboutUpdateForm from "./Forms/AboutUpdateForm";
import RacesUpdateForm from "./Forms/RacesUpdateForm";
import CharactersUpdateForm from "./Forms/CharactersUpdateForm";
import WordsUpdateForm from "./Forms/WordsUpdateForm";
import UserReviewNewForm from "./Forms/UserReviewsNewForm";
import UserReviewUpdateForm from "./Forms/UserReviewUpdateForm";
import BlogCommentNewForm from "./Forms/BlogCommentNewForm";
import BlogCommentUpdateForm from "./Forms/BlogCommentUpdateForm";
import BookReviewCommentNewForm from "./Forms/BookReviewCommentNewForm";
import BookReviewCommentUpdateForm from "./Forms/BookReviewCommentUpdateForm";
import NewEnvVarForm from "./Forms/NewEnvVarForm";
import UpdateEnvVarForm from "./Forms/UpdateEnvVarForm";
import Forgot from "./Forgot";
import AboutForm from "./Updated Scripts/AboutForm";
import BlogForm from "./Updated Scripts/BlogForm";
import NewsForm from "./Updated Scripts/NewsForm";
import BookReviewForm from "./Updated Scripts/BookReviewForm";
import MoreForm from "./Updated Scripts/MoreForm";

const Browser = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/books" element={<BooksPage />} />
                    <Route exact path="/books/:title" element={<Book />} />
                    <Route exact path="/books/new" element={<BookForm />} />
                    <Route exact path="/books/update/:title" element={<BookUpdateForm />} />
                    <Route exact path="/books/delete/:title" element={<BookDelete />} />
                    <Route exact path="/news" element={<NewsPage />} />
                    <Route exact path="/news/new" element={<NewsForm />} />
                    <Route exact path="/news/update/:title" element={<NewsUpdateForm />} />
                    <Route exact path="/news/delete/:title" element={<NewsDelete />} />
                    <Route exact path="/blog" element={<BlogPage />} />
                    <Route exact path="/blog/:title" element={<Blog />} />
                    <Route exact path="/blog/new" element={<BlogForm />} />
                    <Route exact path="/blog/update/:title" element={<BlogUpdateForm />} />
                    <Route exact path="/blog/delete/:title" element={<BlogDelete />} />
                    <Route exact path="/blogComment/new/:title" element={<BlogCommentNewForm />} />
                    <Route exact path="/blogComment/update/:title/:id" element={<BlogCommentUpdateForm />} />
                    <Route exact path="/blogComment/delete/:title/:id" element={<BlogCommentDelete />} />
                    <Route exact path="/reviews" element={<ReviewsPage />} />
                    <Route exact path="/reviews/:title" element={<Review />} />
                    <Route exact path="/reviews/new" element={<BookReviewForm />} />
                    <Route exact path="/reviews/update/:title" element={<BookReviewUpdateForm />} />
                    <Route exact path="/reviews/delete/:title" element={<BookReviewDelete />} />
                    <Route exact path="/about" element={<AboutPage />} />
                    <Route exact path="/about/new" element={<AboutForm />} />
                    <Route exact path="/about/update/:name" element={<AboutUpdateForm />} />
                    <Route exact path="/more" element={<MorePage />} />
                    <Route exact path="/more/new/race" element={<MoreForm formType="race" />} />
                    <Route exact path="/more/new/character" element={<MoreForm formType="character" />} />
                    <Route exact path="/more/new/word" element={<MoreForm formType="word" />} />
                    <Route exact path="/more/update/race/:name" element={<RacesUpdateForm />} />
                    <Route exact path="/more/update/character/:name" element={<CharactersUpdateForm />} />
                    <Route exact path="/more/update/word/:word" element={<WordsUpdateForm />} />
                    <Route exact path="/more/delete/race/:name" element={<RaceDelete />} />
                    <Route exact path="/more/delete/character/:name" element={<CharacterDelete />} />
                    <Route exact path="/more/delete/word/:word" element={<WordDelete />} />
                    <Route exact path="/userReviews/new/:title" element={<UserReviewNewForm />} />
                    <Route exact path="/userReviews/update/:title/:id" element={<UserReviewUpdateForm />} />
                    <Route exact path="/userReviews/delete/:title/:id" element={<UserReviewDelete />} />
                    <Route exact path="/bookReviewComments/new/:title" element={<BookReviewCommentNewForm />} />
                    <Route exact path="/bookReviewComments/update/:title/:id" element={<BookReviewCommentUpdateForm />} />
                    <Route exact path="/bookReviewComments/delete/:title/:id" element={<BookReviewCommentDelete />} />
                    <Route exact path="/users" element={<UsersPage />} />
                    <Route exact path="/users/:username" element={<UserPage />} />
                    <Route exact path="/users/:username/update" element={"Component To Be Passed"} />
                    <Route exact path="/contact" element={<Contact />} />
                    <Route exact path="/forgot" element={<Forgot />} />
                    <Route exact path="/login" element={<LoginForm />} />
                    <Route exact path="/register" element={<RegisterForm />} />
                    <Route exact path="/signout" element={<Signout />} />
                    <Route exact path="/envvar" element={<NewEnvVarForm />} />
                    <Route exact path="/envvar/:name" element={<UpdateEnvVarForm />} />
                    <Route exact path="/" element={<HomePage />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Browser;