import React, { useEffect, useState } from "react";
import api from "../helpers/api";
import useSessionStorage from "../helpers/useSessionStorage";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as filledStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as unfilledStar } from "@fortawesome/free-regular-svg-icons";
import jwt from "jwt-decode";
//import UserReview from "./UserReview";
import "../css/Book.css";
import useLocalStorage from "../helpers/useLocalStorage";

const Book = ({ title }) => {
    const url = window.location.href.split("/")
    const urlTitle = url[url.length-1];
    const bookTitle = (title || (urlTitle && urlTitle.replaceAll("%20", " "))) || "";
    const [token, setToken] = useLocalStorage("token");
    let admin = 0;
    let username = null;
    
    if (token && token !== "undefined") {
        try {
            const decodedToken = jwt(token);
            admin = decodedToken.admin;
            username = decodedToken.username;
        } catch (error) {
            console.error("Invalid token specified:", error);
        }
    }

    const [book, setBook] = useState({});
    const [userReviews, setUserReviews] = useState([]);
    const [books, setBooks] = useSessionStorage("books");
    const [isActive, setIsActive] = useState(false);
    const [featured, setFeatured] = useState(false);

    const fetchBookData = async () => {
        let storedBooks = books ? JSON.parse(books) : [];
        if (!Array.isArray(storedBooks)) {
            storedBooks = [];
        }
        const storedBook = storedBooks.find(bk => bk.title === bookTitle);
        if (storedBook) {
            setBook(storedBook);
            setFeatured(storedBook.featured);
        } else {
            const fetchedBooks = await api.getAll("books");
            setBooks(JSON.stringify(fetchedBooks));
            const newBook = fetchedBooks.find(bk => bk.title === bookTitle);
            if (newBook) {
                setBook(newBook);
                setFeatured(newBook.featured);
            }
        }
    };
    const fetchUserReviews = async () => {
        const reviews = await api.getByTitleOrUsername("userReviews", bookTitle);
        setUserReviews(reviews);
    };

    useEffect(() => {
        fetchBookData();
    }, [bookTitle]);

    useEffect(() => {
        if (book.title) {
            fetchUserReviews();
        }
    }, [book.title]);
    const handleFeaturedToggle = async () => {
        const updatedFeatured = !featured;
        setFeatured(updatedFeatured);
        try {
            await api.updateFeaturedStatus(book.title, updatedFeatured);
            const updatedBooks = await api.getAll("books");
            setBooks(JSON.stringify(updatedBooks));
        } catch (error) {
            console.error("Failed to update featured status:", error);
            setFeatured(!updatedFeatured); // Revert the change if the update fails
        }
    };
    const calculateRating = () => {
        if (userReviews.length === 0) return null;
        const totalRating = userReviews.reduce((acc, review) => acc + review.rating, 0);
        return (totalRating / userReviews.length).toFixed(1);
    };

    const rating = calculateRating();

    const renderStars = () => {
        const starsArr = [];
        for (let i = 0; i < 5; i++) {
            starsArr.push(
                <p className="starRating" key={`empty-star-${i}`}>
                    <FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={unfilledStar} />
                </p>
            );
        }
        for (let i = 0; i < rating; i++) {
            starsArr[i] = (
                <p className="starRating" key={`star-${i}`}>
                    <FontAwesomeIcon id="iconBtn" className="ratingIcon" icon={filledStar} />
                </p>
            );
        }
        return starsArr;
    };

    if (!book.title) return null;

    return (
        <div className="bookDiv">
            <div className="leftCol">
                <img className="bookImg" src={book.image} alt={`${book.title}-cover`} />
                {urlTitle.replaceAll("%20", " ") !== bookTitle && (
                    <p className="imgNote">Click image to visit page</p>
                )}
            </div>
            <div className="centerCol">
                <h2 className="bookTitle">{book.title}</h2>
                <h4 className="bookSeries">
                    {book.series} {book.bookNumber ? `Book ${book.bookNumber}` : null}
                </h4>
                <ReactMarkdown remarkPlugins={[gfm, remarkBreaks]} className="bookContent">
                    {book.description}
                </ReactMarkdown>
                {rating ?
                    <div className="ratingDiv">
                        <p className="bookRating"><strong>Rating</strong></p>
                        {renderStars()} ({rating})
                    </div> : null
                }
                {admin ?
                    <>
                        <p>
                            <a href={`/books/update/${book.title}`} className="updateBtn">
                                Update Info
                            </a>
                        </p>
                        <p>
                            <a href={`/books/delete/${book.title}`} className="deleteBtn">
                                Delete Book
                            </a>
                        </p>
                    </> : null
                }
                {username ?
                    <p>
                        <a href={`/userReviews/new/${book.title}`} className="newContentBtn">
                            Add User Review
                        </a>
                    </p> : null
                }
            </div>
            <div className="rightCol">
                {admin ?
                    <div className="featuredDiv">
                        <p className="featuredStatus"><strong>Featured</strong></p>
                        <button className="featuredBtn" onClick={handleFeaturedToggle}>
                            <FontAwesomeIcon id="iconBtn" className="featuredIcon" icon={featured ? filledStar : unfilledStar} />
                        </button>
                    </div> : null
                }
                {book.release_date && (
                    <>
                        <p className="bookDetails bookRelease"><strong>Release Date</strong></p>
                        <p className="bookDetails">{book.release_date}</p>
                    </>
                )}
                {book.publisher ? 
                    <>
                        <p className="bookDetails bookPublisher"><strong>Publisher</strong></p>
                        <p className="bookDetails">{book.publisher}</p>
                    </> : null
                }
                {book.paperback_pages ?
                    <>
                        <p className="bookDetails bookPagesHead"><strong>Paperback Pages</strong></p>
                        <p className="bookDetails">{book.paperback_pages}</p>
                    </> : null
                }
                {book.audiobook_length && book.audiobook_link && (
                    <>
                        <p className="bookDetails audiobook"><strong>Audiobook Length</strong></p>
                        <p className="bookDetails audiobookLength">{book.audiobook_length}</p>
                    </>
                )}
                { book.harcover_isbn ? 
                    <>
                        <p className="bookDetails bookIsbn"><strong>Hardcover ISBN</strong></p>
                        <p className="bookDetails">{book.hardcover_isbn}</p>
                    </> : null }
                <p className="bookDetails bookIsbn"><strong>Paperback ISBN</strong></p>
                <p className="bookDetails">{book.paperback_isbn}</p>
                <p className="bookDetails bookIsbn"><strong>eBook ISBN</strong></p>
                <p className="bookDetails">{book.ebook_isbn}</p>
                <p className="bookDetails bookLinkHeading"><strong>Links</strong></p>
                <a className="bookDetails bookLink" href={book.ebook}>eBook</a>
                <a className="bookDetails bookLink" href={book.hardcover}>Hardcover</a>
                <a className="bookDetails bookLink" href={book.paperback}>Paperback</a>
                {book.audiobookLink && (
                    <a className="bookDetails bookLink" href={book.audiobook_link}>Audiobook</a>
                )}
            </div>
            {/* {book.title === bookTitle && userReviews.length > 0 && (
                <div className="userReviewsDiv">
                    <p>User Reviews <button id="userReviewsShow" onClick={() => setIsActive(!isActive)} className="showHideBtn">{isActive ? "Hide Reviews" : "Show Reviews"}</button></p>
                    <div className={`userReview ${isActive ? "" : "hidden"}`}>
                        {userReviews.map(ur => (
                            <UserReview review={ur} key={`${ur.username}-${ur.id}`} />
                        ))}
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default Book;
