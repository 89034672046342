import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import "../../css/BookForm.css";

const BookForm = ({ initialData = null }) => {
    const [books, setBooks] = useSessionStorage("books");
    const [formData, setFormData] = useState({
        title: "",
        series: "",
        bookNumber: "",
        author: "",
        releaseDate: "",
        publisher: "",
        description: "",
        image: "",
        ebook: "",
        ebookPages: "",
        ebookIsbn: "",
        hardcover: "",
        hardcoverPages: "",
        hardcoverIsbn: "",
        paperback: "",
        paperbackPages: "",
        paperbackIsbn: "",
        audiobookLink: "",
        audiobookLength: "",
        featured: false
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                featured: Boolean(initialData.featured)
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(data => ({
            ...data,
            [name]: ['bookNumber', 'ebookPages', 'hardcoverPages', 'paperbackPages'].includes(name) ? (value === "" ? "" : Number(value)) : value
        }));
    };

    const handleFeaturedChange = e => {
        setFormData(data => ({
            ...data,
            featured: e.target.checked
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const dataToSubmit = {
            ...formData,
            book_number: formData.bookNumber === "" ? null : formData.bookNumber,
            ebook_pages: formData.ebookPages === "" ? null : formData.ebookPages,
            hardcover_pages: formData.hardcoverPages === "" ? null : formData.hardcoverPages,
            paperback_pages: formData.paperbackPages === "" ? null : formData.paperbackPages,
            audiobook_length: formData.audiobookLength === "" ? null : formData.audiobookLength
        };

        delete dataToSubmit.bookNumber;
        delete dataToSubmit.ebookPages;
        delete dataToSubmit.hardcoverPages;
        delete dataToSubmit.paperbackPages;
        delete dataToSubmit.audiobookLength;
        
        try{
            if (isUpdating) {
                await api.updateBook(dataToSubmit);
            } else {
                await api.createBook(dataToSubmit);
            }
            window.sessionStorage.removeItem("books");
            const updatedBooks = await api.getAll("books");
            setBooks(JSON.stringify(updatedBooks));
            navigate("/books");
        } catch (error) {
            console.error('API Request Failed: ', error);
        }
    };

    return (
        <div className="bookFormParent">
            <h2>{isUpdating ? `Update ${formData.title}` : "Add New Book"}</h2>
            <form className="bookForm" onSubmit={handleSubmit}>
                <div className="bookFormDiv">
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            required
                            value={formData.title}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            value={formData.title}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookSeries"
                            placeholder="Series"
                            name="series"
                            id="series"
                            value={formData.series}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookNumber"
                            placeholder="Book Number"
                            name="bookNumber"
                            id="bookNumber"
                            value={formData.bookNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookAuthor"
                            placeholder="Author"
                            name="author"
                            id="author"
                            required
                            value={formData.author}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookAuthor"
                            placeholder="Author"
                            name="author"
                            id="author"
                            value={formData.author}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookRelease"
                            placeholder="Release Date"
                            name="releaseDate"
                            id="releaseDate"
                            required
                            value={formData.releaseDate}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookRelease"
                            placeholder="Release Date"
                            name="releaseDate"
                            id="releaseDate"
                            value={formData.releaseDate}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookPublisher"
                            placeholder="Publisher"
                            name="publisher"
                            id="publisher"
                            required
                            value={formData.publisher}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookPublisher"
                            placeholder="Publisher"
                            name="publisher"
                            id="publisher"
                            value={formData.publisher}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        {!initialData ? <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea bookDescription"
                            placeholder="Description"
                            name="description"
                            id="description"
                            required
                            value={formData.description}
                            onChange={handleChange}
                        /> : <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea bookDescription"
                            placeholder="Description"
                            name="description"
                            id="description"
                            value={formData.description}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookImage"
                            placeholder="Image Link"
                            name="image"
                            id="image"
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookEbook"
                            placeholder="eBook Link"
                            name="ebook"
                            id="ebook"
                            value={formData.ebook}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookEbookIsbn"
                            placeholder="eBook ISBN"
                            name="ebookIsbn"
                            id="ebookIsbn"
                            value={formData.ebookIsbn}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookEbookPages"
                            placeholder="eBook Pages"
                            name="ebookPages"
                            id="ebookPages"
                            required
                            value={formData.ebookPages}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookEbookPages"
                            placeholder="eBook Pages"
                            name="ebookPages"
                            id="ebookPages"
                            value={formData.ebookPages}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookHardcover"
                            placeholder="Hardcover Link"
                            name="hardcover"
                            id="hardcover"
                            value={formData.hardcover}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookHardcoverIsbn"
                            placeholder="Hardcover ISBN"
                            name="hardcoverIsbn"
                            id="hardcoverIsbn"
                            value={formData.hardcoverIsbn}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookHardcoverPages"
                            placeholder="Hardcover Pages"
                            name="hardcoverPages"
                            id="hardcoverPages"
                            required
                            value={formData.hardcoverPages}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookHardcoverPages"
                            placeholder="Hardcover Pages"
                            name="hardcoverPages"
                            id="hardcoverPages"
                            value={formData.hardcoverPages}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookPaperback"
                            placeholder="Paperback Link"
                            name="paperback"
                            id="paperback"
                            value={formData.paperback}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookPaperbackIsbn"
                            placeholder="Paperback ISBN"
                            name="paperbackIsbn"
                            id="paperbackIsbn"
                            value={formData.paperbackIsbn}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        {!initialData ? <input
                            type="text"
                            className="formInputs bookPaperbackPages"
                            placeholder="Paperback Pages"
                            name="paperbackPages"
                            id="paperbackPages"
                            required
                            value={formData.paperbackPages}
                            onChange={handleChange}
                        /> : <input
                            type="text"
                            className="formInputs bookPaperbackPages"
                            placeholder="Paperback Pages"
                            name="paperbackPages"
                            id="paperbackPages"
                            value={formData.paperbackPages}
                            onChange={handleChange}
                        />}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookAudiobook"
                            placeholder="Audiobook Link"
                            name="audiobookLink"
                            id="audiobookLink"
                            value={formData.audiobookLink}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookAudiobookLength"
                            placeholder="Audiobook Length"
                            name="audiobookLength"
                            id="audiobookLength"
                            value={formData.audiobookLength}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="featuredDiv">
                        <label className="featuredLabel">Featured</label>
                        <input
                            type="checkbox"
                            className="formInputs bookFeatured"
                            name="featured"
                            id="featured"
                            checked={formData.featured}
                            onChange={handleFeaturedChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BookForm;
