import { useState } from "react";
import api from "../helpers/api";
import useSessionStorage from "../helpers/useSessionStorage";

const BlogDelete = () => {
    const [blogs, setBlogs] = useSessionStorage("blogs");
    const [isSet, setIsSet] = useState(false);
    let url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteBlog = async (title) => {
            await api.deleteByOneIdentifier("blog", title);
        }
        deleteBlog(title);
        window.sessionStorage.removeItem("blogs");
        const updateBlogs = async () => {
            const temp = [];
            const blgsRes = await api.getAll("blog");
            for(let i = 0; i < blgsRes.length; i++){
                temp.push(blgsRes[i]);
            }
            await setBlogs(JSON.stringify(temp));
            setIsSet(true);
        }
        updateBlogs();
    } else {
        window.location.href="/blog";
    }
}

export default BlogDelete