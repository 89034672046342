import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import MoreForm from "../Updated Scripts/MoreForm"; // Adjust the path as needed

const CharacterUpdateForm = () => {
    const { name } = useParams();
    const [characters] = useSessionStorage("characters");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (characters) {
            const charactersArray = JSON.parse(characters);
            const characterData = charactersArray.find(c => c.name === name);
            setInitialData(characterData);
        }
    }, [name, characters]);

    return (
        <div>
            {initialData ? <MoreForm formType="character" initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default CharacterUpdateForm;
