import React, { useState, useEffect } from "react";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import "../../css/MoreForm.css";

const MoreForm = ({ formType, initialData = null }) => {
    const [races, setRaces] = useSessionStorage("races");
    const [characters, setCharacters] = useSessionStorage("characters");
    const [words, setWords] = useSessionStorage("words")
    const [formData, setFormData] = useState({
        name: "",
        magic: "",
        details: "",
        race: "",
        bio: "",
        word: "",
        pronounce: "",
        definition: "",
        image: "",
        audio: "",
    });

    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                image: initialData.image || "",
                audio: initialData.audio || ""
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => ({
            ...data,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formType === "race") {
                const dataToSubmit = {
                    name: formData.name,
                    magic: formData.magic,
                    details: formData.details,
                    image: formData.image,
                };
                if (isUpdating) {
                    await api.updateRace(initialData.name, dataToSubmit);
                } else {
                    await api.createRace(dataToSubmit);
                }
                const updatedRaces = await api.getAll("races");
                setRaces(JSON.stringify(updatedRaces));
            } else if (formType === "character") {
                const dataToSubmit = {
                    name: formData.name,
                    race: formData.race,
                    bio: formData.bio,
                    image: formData.image,
                };
                if (isUpdating) {
                    await api.updateCharacter(initialData.name, dataToSubmit);
                } else {
                    await api.createCharacter(dataToSubmit);
                }
                const updatedCharacters = await api.getAll("characters");
                setCharacters(JSON.stringify(updatedCharacters));
            } else if (formType === "word") {
                const dataToSubmit = {
                    word: formData.word,
                    pronounce: formData.pronounce,
                    definition: formData.definition,
                    audio: formData.audio,
                };
                if (isUpdating) {
                    await api.updateWord(initialData.word, dataToSubmit);
                } else {
                    await api.createWord(dataToSubmit);
                }
                const updatedWords = await api.getAll("pronunciation");
                setWords(JSON.stringify(updatedWords));
            }

            // Clear form data and update session storage
            setFormData({
                name: "",
                magic: "",
                details: "",
                race: "",
                bio: "",
                word: "",
                pronounce: "",
                definition: "",
                image: "",
                audio: "",
            });
            window.location.href = "/more";  // Redirect to More page after submission
        } catch (error) {
            console.error("API Request Failed: ", error);
        }
    };

    return (
        <div className="moreFormParent">
            <h2>{isUpdating ? `Update ${formType.charAt(0).toUpperCase() + formType.slice(1)}` : `Add New ${formType.charAt(0).toUpperCase() + formType.slice(1)}`}</h2>
            <form className="moreForm" onSubmit={handleSubmit}>
                <div className="moreFormDiv">
                    {formType === "race" && (
                        <>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs raceName"
                                    placeholder="Name"
                                    name="name"
                                    id="name"
                                    required={!isUpdating}
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs raceMagic"
                                    placeholder="Magic"
                                    name="magic"
                                    id="magic"
                                    required={!isUpdating}
                                    value={formData.magic}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <textarea
                                    rows="20"
                                    cols="100"
                                    className="formTextArea raceDetails"
                                    placeholder="Details"
                                    name="details"
                                    id="details"
                                    required={!isUpdating}
                                    value={formData.details}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs raceImage"
                                    placeholder="Image"
                                    name="image"
                                    id="image"
                                    value={formData.image}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}
                    {formType === "character" && (
                        <>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs characterName"
                                    placeholder="Name"
                                    name="name"
                                    id="name"
                                    required={!isUpdating}
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs characterRace"
                                    placeholder="Race"
                                    name="race"
                                    id="race"
                                    required={!isUpdating}
                                    value={formData.race}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <textarea
                                    rows="20"
                                    cols="100"
                                    className="formTextArea characterBio"
                                    placeholder="Bio"
                                    name="bio"
                                    id="bio"
                                    required={!isUpdating}
                                    value={formData.bio}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs characterImage"
                                    placeholder="Image"
                                    name="image"
                                    id="image"
                                    value={formData.image}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}
                    {formType === "word" && (
                        <>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs wordWord"
                                    placeholder="Word"
                                    name="word"
                                    id="word"
                                    required={!isUpdating}
                                    value={formData.word}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs wordPronounce"
                                    placeholder="Pronunciation"
                                    name="pronounce"
                                    id="pronounce"
                                    required={!isUpdating}
                                    value={formData.pronounce}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <textarea
                                    rows="20"
                                    cols="100"
                                    className="formTextArea wordDefinition"
                                    placeholder="Definition"
                                    name="definition"
                                    id="definition"
                                    required={!isUpdating}
                                    value={formData.definition}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="formInputs wordAudio"
                                    placeholder="Audio"
                                    name="audio"
                                    id="audio"
                                    value={formData.audio}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    )}
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MoreForm;
