import React, {useState} from "react";
import api from "../../helpers/api";
import "../../css/EnvVarForm.css";

const NewEnvVarForm = () => {
    const INITIAL_STATE = {
        name: "",
        value: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        await api.createVar(formData.name, formData.value);
        setFormData(INITIAL_STATE);
        window.location.href="/envvar";
    }

    return (
        <div>
            <h1 className="envVarH1">Add Variable</h1>
            <form className="envVarForm" onSubmit={handleSubmit}>
                <div className="inputDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Variable Name"
                            name="name"
                            id="name"
                            key="name"
                            value={formData.name}
                            required
                            onChange={handleChange} />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Variable Value"
                            name="value"
                            id="value"
                            key="value"
                            value={formData.value}
                            required
                            onChange={handleChange} />
                    </div>
                </div>
                <div className="btnDiv">
                    <button className="submitBtn">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default NewEnvVarForm;