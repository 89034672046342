import React from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import "../css/Character.css";

const Character = ({character}) => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    return (
        <div className="characterDiv">
            <div className="characterInfoDiv">
                <h1 className="characterName">{character.name}</h1>
                <h3 className="characterRace">Race: {character.race}</h3>
                <ReactMarkdown remarkPlugins={[gfm, remarkBreaks]} className="characterBio">{character.bio}</ReactMarkdown>
            </div>
            <div className="rightMoreCol">
                {character.image ? <div className="characterImgDiv">
                    <img className="characterImg" src={character.image} alt={`${character.name} concept image`} />
                </div>: null}
                {admin ? <div className="infoMgmtDiv">
                    <div className="mgmtBtn">
                        <a href={`/more/update/character/${character.name}`} className="updateBtn" key="updateWordBtn">Update Character</a>
                    </div>
                    <div className="mgmtBtn">
                        <a href={`/more/delete/character/${character.name}`} className="deleteBtn" key="deleteWordBtn">Delete Character</a>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default Character;