import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import MoreForm from "../Updated Scripts/MoreForm"; // Adjust the path as needed

const RaceUpdateForm = () => {
    const { name } = useParams();
    const [races] = useSessionStorage("races");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (races) {
            const racesArray = JSON.parse(races);
            const raceData = racesArray.find(r => r.name === name);
            setInitialData(raceData);
        }
    }, [name, races]);

    return (
        <div>
            {initialData ? <MoreForm formType="race" initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default RaceUpdateForm;
