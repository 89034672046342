import React, {useState} from "react";
import api from "../../helpers/api";
import "../../css/EnvVarForm.css";

const NewEnvVarForm = () => {
    const url = window.location.href.split("/");
    const name = url[url.length-1];
    const INITIAL_STATE = {
        value: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        await api.updateVars(name, formData.value);
        setFormData(INITIAL_STATE);
        window.location.href=`/`;
    }

    return (
        <div>
            <h1 className="envVarH1">Update {name} Variable Value</h1>
            <form className="envVarForm" onSubmit={handleSubmit}>
                <div className="inputDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Variable Value"
                            name="value"
                            id="value"
                            key="value"
                            value={formData.value}
                            required
                            onChange={handleChange} />
                    </div>
                </div>
                <div className="btnDiv">
                    <button className="submitBtn">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default NewEnvVarForm;