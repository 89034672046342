import React, {useState, useEffect} from "react";
import jwt from "jwt-decode"
import api from "../helpers/api";
import User from "./User";
import "../css/UserPage.css";
import useLocalStorage from "../helpers/useLocalStorage";

const UserPage = () => {
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;
    const [user, setUser] = useState([]);
    useEffect(()=> {
        const gatherUser = async () => {
            let resources = await api.getByTitleOrUsername("users", username);
            let temp = []
            temp.push(resources);
            setUser(temp);
        }
        gatherUser();
    }, []);
    return (
        <>
            <div className="userPageDiv">
                {user.length > 0 ? user.map(u => <User user={u} key={`User-${username}`} />) : null}
            </div>
        </>
    )
}

export default UserPage;