import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import BlogForm from "../Updated Scripts/BlogForm"; // Adjust the path as needed

const BlogUpdateForm = () => {
    const { title } = useParams();
    const [blogs] = useSessionStorage("blogs");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (blogs) {
            const blogsArray = JSON.parse(blogs);
            const blog = blogsArray.find(b => b.title === title);
            setInitialData(blog);
        }
    }, [title, blogs]);

    return (
        <div>
            {initialData ? <BlogForm initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default BlogUpdateForm;
