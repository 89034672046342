import React, { useEffect, useState, useRef } from "react";
import "../css/Carousel.css";

const Carousel = ({ items, interval = 5000 }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const timerRef = useRef(null);

    const resetTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, interval);
    };

    useEffect(() => {
        resetTimer();
        return () => clearInterval(timerRef.current);
    }, [items, interval]);

    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        resetTimer();
    };

    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
        resetTimer();
    };

    return (
        <div className="carousel">
            <div className="carousel-inner">
                {items.map((item, index) => (
                    <div key={index} className={`carousel-item ${index === activeIndex ? "active" : ""}`}>
                        {item}
                    </div>
                ))}
            </div>
            <button className="carousel-control prev" onClick={prevSlide}>&#10094;</button>
            <button className="carousel-control next" onClick={nextSlide}>&#10095;</button>
        </div>
    );
};

export default Carousel;
