import {useEffect, useState} from "react";

const useSessionStorage = (key, defaultValue) => {
    const [state, setState] = useState(()=> {
        let value = window.sessionStorage.getItem(key) || defaultValue;

        return value;
    });
    useEffect(() => {
        window.sessionStorage.setItem(key, state);
    }, [state, key]);

    return [state, setState];
}

export default useSessionStorage;