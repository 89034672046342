import React from "react";
import ForgotForm from "./Forms/ForgotForm"
import "../css/ForgotForm.css";

const Forgot = () => {
    return(
        <div className="forgotPage">
            <h1 className="forgotH1">Forgot Password?</h1>
            <ForgotForm />
        </div>
    )
}

export default Forgot;