import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import AboutForm from "../Updated Scripts/AboutForm"; // Adjust the path as needed

const AboutUpdateForm = () => {
    const { name } = useParams();
    const [about, setAbout] = useSessionStorage("about");
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            let aboutData = about ? JSON.parse(about) : null;
            if (!aboutData || aboutData.name !== name) {
                const fetchedAbout = await api.getAll("about");
                aboutData = fetchedAbout.find(abt => abt.name === name);
                if (aboutData) {
                    setAbout(JSON.stringify(fetchedAbout));
                }
            }
            setInitialData(aboutData);
        };
        fetchAboutData();
    }, [name, about, setAbout]);

    if (!initialData) {
        return <div>Loading...</div>;
    }

    return <AboutForm initialData={initialData} />;
};

export default AboutUpdateForm;
