import React from "react";
import { useParams } from "react-router-dom";
import useSessionStorage from "../../helpers/useSessionStorage";
import MoreForm from "../Updated Scripts/MoreForm"; // Adjust the path as needed

const WordUpdateForm = () => {
    const { word } = useParams();
    const [words] = useSessionStorage("words");
    const [initialData, setInitialData] = React.useState(null);

    React.useEffect(() => {
        if (words) {
            const wordsArray = JSON.parse(words);
            const wordData = wordsArray.find(w => w.word === word);
            setInitialData(wordData);
        }
    }, [word, words]);

    return (
        <div>
            {initialData ? <MoreForm formType="word" initialData={initialData} /> : <div>Loading...</div>}
        </div>
    );
};

export default WordUpdateForm;
