import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import jwt from "jwt-decode";
import api from "../../helpers/api";
import useLocalStorage from "../../helpers/useLocalStorage";
import "../../css/LoginForm.css";

const Login = () => {
    const [token, setToken] = useLocalStorage("token");
    const [errorsExist, setErrorsExist] = useState(false);
    let username;
    const INITIAL_STATE = {
        username: "",
        password: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            let uToken = await api.login(formData.username, formData.password);
            setToken(uToken);
            let uName = jwt(uToken);
            username = uName.username;
            setFormData(INITIAL_STATE);
            window.location.href = "/";
        } catch (err) {
            if(err) {
                setErrorsExist(current => !current);
            }
        }
    }
    const userSignedIn = username !== undefined ? <Navigate to="/" /> : <div className="loginParent">
        <h1 className="loginH1">Login</h1>
        <form className="loginForm" onSubmit={handleSubmit} action="/token" method="POST">
            <div className="inputDiv">
                <div>
                    <input
                        type="text"
                        className="formInputs"
                        placeholder="Username"
                        name="username"
                        id="username"
                        key="username"
                        value={formData.username}
                        onChange={handleChange} />
                </div>
                <div>
                    <input
                        type="password"
                        className="formInputs"
                        placeholder="Password"
                        name="password"
                        id="password"
                        key="password"
                        value={formData.password}
                        onChange={handleChange} />
                </div>
            </div>
            <div className="btnDiv">
                <button className="submitBtn">Login</button>
            </div>
            <div className="forgotDiv">
                <a className="forgotBtn" href="/forgot">Forgot Password?</a>
            </div>
        </form>
    </div>;
    return (
        <div>
            <p id="errors" className={`errorsContainer ${errorsExist ? "" : "hidden"}`}>{errorsExist ? "Username or Password is incorrect." : null}</p>
            {userSignedIn}
        </div>
    )
}

export default Login;