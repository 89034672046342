import React, {useState} from "react";
import api from "../../helpers/api";
import jwt from "jwt-decode";
import useLocalStorage from "../../helpers/useLocalStorage";
import "../../css/UserReviewsNewForm.css";

const UserReviewsNewForm = () => {
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;
    const url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");
    const d = new Date();
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    const milliseconds = d.getMilliseconds();
    const date = `${year}-${month+1}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    const INITIAL_STATE = {
        reviewTitle:"",
        review:"",
        contentRating:"",
        flowRating:"",
        editRating: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const c = parseFloat(formData.contentRating);
        const f = parseFloat(formData.flowRating);
        const ed = parseFloat(formData.editRating);
        await api.createUserReview(username, title, formData.reviewTitle, c, f, ed, formData.review, date);
        setFormData(INITIAL_STATE);
        window.location.href = `/books/${title}`;
    }
    return (
        <div className="userReviewNewParent">
            <h2>New User Review by {username}</h2>
            <form className="userReviewNewForm" onSubmit={handleSubmit}>
                <div className="userReviewNewDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs userReviewTitle"
                            placeholder="Title"
                            name="reviewTitle"
                            id="reviewTitle"
                            key="reviewTitle"
                            required
                            value={formData.reviewTitle}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea userReviewReview"
                            placeholder="Review"
                            name="review"
                            id="review"
                            key="review"
                            required
                            value={formData.review}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"
                            className="formRating userReviewRating"
                            placeholder="Content Rating"
                            min="1"
                            max="5"
                            name="contentRating"
                            id="contentRating"
                            key="contentRating"
                            required
                            value={formData.contentRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"                            
                            className="formRating userReviewRating"
                            placeholder="Flow Rating"
                            min="1"
                            max="5"
                            name="flowRating"
                            id="flowRating"
                            key="flowRating"
                            required
                            value={formData.flowRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="0.1"                            
                            className="formRating userReviewRating"
                            placeholder="Edit Rating"
                            min="1"
                            max="5"
                            name="editRating"
                            id="editRating"
                            key="editRating"
                            required
                            value={formData.editRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default UserReviewsNewForm;