import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faTwitter, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import EmailForm from "./Forms/EmailForm";
import "../css/Contact.css";
import EmailListForm from "./Forms/EmailListForm";

const Contact = () => {
    const handleClick = (e) => {
        const value = "author@kennethlpowell.com";
        navigator.clipboard.writeText(value);
    }
    return(
        <div className="contactSocial">
            <div className="emailFormDiv">
                <EmailForm />
            </div>
            <div className="emailAddressCopyDiv">
                <p className="contactEmailCopyBtn">
                    If you would like to send images, please press this button to copy the author's email address. 
                    Paste it into your email and send me the images! Love seeing them and I'd be happy to feature 
                    you on the site.<button className="contactEmailButton" onClick={handleClick}>
                    <FontAwesomeIcon className="contactEmailIcon" icon={faEnvelope} /></button>
                </p>
                <p className="contactEmailAddendum">Clicking envelope will copy email address to your clipboard.</p>
            </div>
            <div className="social">
                <h2 className="socialHeader">Social Media</h2>
                <p className="socialMessage">The author can be reached at any of the below social media platforms.</p>
                <a className="socialBtn" href="https://www.facebook.com/KennethLPowell">
                    <FontAwesomeIcon className="socialFB" icon={faFacebook} />
                </a>
                <a className="socialBtn" href="https://www.instagram.com/kailclaskan/">
                    <FontAwesomeIcon className="socialIg" icon={faInstagram} />
                </a>
                <a className="socialBtn" href="https://twitter.com/KennethLPowell">
                    <FontAwesomeIcon className="socialTw" icon={faTwitter} />
                </a>
                <a className="socialBtn" href="https://www.youtube.com/channel/UCFBwn7erJIY7ou1-IsT6F_w">
                    <FontAwesomeIcon className="socialYt" icon={faYoutube} />
                </a>
            </div>
            <div className="emailListSignup">
                <h3 className="emailListLabel">Signup for all the latest news and information from Kenneth L Powell.</h3>
                <EmailListForm />
            </div>
        </div>
    )
}

export default Contact;