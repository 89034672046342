import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import jwt from "jwt-decode";
import api from "../../helpers/api";
import useLocalStorage from "../../helpers/useLocalStorage";
import "../../css/RegisterForm.css";

const RegisterForm = () => {
    const [token, setToken] = useLocalStorage("token");
    const [errorsExist, setErrorsExist] = useState(false);
    let errorMessage = "";
    let statusMessage = "";
    let username;
    const INITIAL_STATE = {
        username: "",
        password:"",
        email:"",
        firstName:"",
        lastName:"",
        securityQuestion:"",
        securityAnswer:"",
        birthDate:""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        statusMessage = "Loading"
        try{
            if(/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(formData.birthDate)){
                let uToken = await api.register(formData.username, formData.password, formData.email, formData.firstName, formData.lastName, formData.securityQuestion, formData.securityAnswer, formData.birthDate);
                setToken(uToken);
                let uName = jwt(uToken);
                username = uName.username;
                setFormData(INITIAL_STATE);
                window.location.href = "/";
            } else {
                errorMessage = "Birth Date is in the incorrect format. Please enter with format mm/dd/yyyy."
                setErrorsExist(true)
            }
        } catch(err) {
            errorMessage = "Username already taken."
            setErrorsExist(true);
        }
    }
    const userForm = username === undefined ? <div className="userSignup">
    <h1 className="signupH1">Signup</h1>
    <form className="signupForm" onSubmit={handleSubmit}>
        <div className="signupDiv">
            <div>
                <input
                    type="text"
                    className="formInputs signupUsername"
                    placeholder="Username"
                    name="username"
                    id="username"
                    key="username"
                    required
                    value={formData.username}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="password"
                    className="formInputs signupPassword"
                    placeholder="Password"
                    name="password"
                    id="password"
                    key="password"
                    required
                    minLength="12"
                    value={formData.password}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="email"
                    className="formInputs signupEmail"
                    placeholder="Email"
                    name="email"
                    id="email"
                    key="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="text"
                    className="formInputs signupFirstName"
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                    key="firstName"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="text"
                    className="formInputs signupLastName"
                    placeholder="Last Name"
                    name="lastName"
                    id="lastName"
                    key="lastName"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="text"
                    className="formInputs signupSecurityQuestion"
                    placeholder="Security Question"
                    name="securityQuestion"
                    id="securityQuestion"
                    key="securityQuestion"
                    required
                    value={formData.securityQuestion}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="password"
                    className="formInputs signupSecurityAnswer"
                    placeholder="Security Answer"
                    name="securityAnswer"
                    id="securityAnswer"
                    key="securityAnswer"
                    required
                    value={formData.securityAnswer}
                    onChange={handleChange}
                />
            </div>
            <div>
                <input
                    type="password"
                    className="formInputs signupBirthDate"
                    placeholder="Birth Date in format mm/dd/yyyy"
                    name="birthDate"
                    id="birthDate"
                    key="birthDate"
                    required
                    value={formData.birthDate}
                    onChange={handleChange}
                />
            </div>
            <div>
                <button className="submitBtn">Register</button>
            </div>
        </div>
    </form>
</div> : <Navigate to="/" />
    return (<div>
        <p id="errors" className={`errorsContainer ${errorsExist ? "" : "hidden"}`}>{errorsExist ? errorMessage : null}</p>
        <h2 id="status" className="statusMessage">{statusMessage}</h2>
        {userForm}
    </div>)
}

export default RegisterForm;