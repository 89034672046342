import React, {useEffect, useState} from "react";
import { send } from "emailjs-com";
import api from "../../helpers/api";
import "../../css/EmailForm.css";

const EmailForm = () => {
    const INITIAL_STATE = {
        email:"",
        subject:"",
        content:""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [service, setService] = useState("");
    const [template, setTemplate] = useState("");
    const [key, setKey] = useState("");
    useEffect(()=> {
        const gatherResources = async () => {
            const res = await api.getEnvVars();
            for(let result of res){
                if(result.name === "EmailService"){
                    setService(result.value);
                }else if(result.name === "EmailPassword") {
                    setKey(result.value);
                } else if(result.name === "template"){
                    setTemplate(result.value);
                }
            }
        }
        gatherResources();
    }, []);
    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        send(
            service,
            template,
            {
                reply_to: formData.email,
                to_name: "author@kennethlpowell.com",
                subject: formData.subject,
                message: formData.content
            },
            key
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
          setFormData(INITIAL_STATE);
    }
    return (
        <div className="emailFormParent">
            <h2>Email the Author</h2>
            <form className="emailForm" onSubmit={handleSubmit}>
                <div className="emailFormDiv">
                    <div>
                        <input
                            type="email"
                            className="formInputs emailFormEmail"
                            placeholder="Your Email Address"
                            name="email"
                            id="email"
                            key="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs emailFormSubject"
                            placeholder="Subject of Your Email (Please Be Descriptive)"
                            name="subject"
                            id="subject"
                            key="subject"
                            required
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea emailFormContent"
                            placeholder="Your content to send to the author. If you have images, please email from your email service. Email address can be copied with the button below."
                            name="content"
                            id="content"
                            key="content"
                            required
                            value={formData.content}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EmailForm;