import { useState, useEffect } from "react";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";

const RaceDelete = () => {
    const [races, setRaces] = useSessionStorage("races");
    const [isSet, setIsSet] = useState(false);
    let url = window.location.href.split("/");
    const name = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteRace = async (name) => {
            await api.deleteByOneIdentifier("races", name);
        }
        deleteRace(name);
        window.sessionStorage.removeItem("races");
        const updateRaces = async () => {
            const temp = [];
            const racRes = await api.getAll("races");
            for(let i = 0; i < racRes.length; i++){
                temp.push(racRes[i]);
            }
            await setRaces(JSON.stringify(temp));
            setIsSet(true);
        }
        updateRaces();
    } else {
        window.location.href = "/more";
    }
}

export default RaceDelete