import { useEffect } from "react";
import useLocalStorage from "../helpers/useLocalStorage";

const Signout = () => {
    const [token, setToken] = useLocalStorage("token");
    useEffect(() => {
        // window.localStorage.clear();
        setToken("undefined")
    }, [token]);
    window.location.href="/";
}

export default Signout;