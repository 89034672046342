import React, {useEffect, useState} from "react";
import MailChimp from "react-mailchimp-form";
import api from "../../helpers/api";
import "../../css/EmailListForm.css"

const EmailListForm = () => {
    const [url, setUrl] = useState("");
    useEffect(()=>{
        const gatherResources = async () => {
            const res = await api.getEnvVars();
            for(let result of res){
                if(result.name === "MailChimpUrl"){
                    setUrl(result.value);
                }
            }
        }
        gatherResources();
    }, []);
    const emailForm = () => <MailChimp 
        action={url}
        className="emailListForm"
        fields={[
            {
                name: "EMAIL",
                placeholder: "Email",
                type: "email",
                required: true
            }, 
            {
                name: "FNAME",
                placeholder: "First Name",
                type: "text",
                required: false
            },
            {
                name: "LNAME",
                placeholder: "Last Name",
                type: "text",
                required: false
            }
        ]}
        messages = {
            {
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "You must write an e-mail.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "Subscribe!"
            }
        }
    />
    return emailForm();
}

export default EmailListForm;