import React, {useState, useEffect, useContext} from "react";
import jwt from "jwt-decode";
import api from "../helpers/api";
import User from "./User";
import "../css/UsersPages.css";
import useLocalStorage from "../helpers/useLocalStorage";

const UsersPage = () => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const username = token !== "undefined" ? jwt(token).username : null;
    const [users, setUsers] = useState([]);
    useEffect(()=> {
        const gatherUsers = async () => {
            let resources;
            if(admin){
                resources = await api.getAll("users");
            } else {
                resources = await api.getByTitleOrUsername("users", username);
            }
            const temp = [];
            for(let i = 0; i < resources.length; i++){
                temp.push(resources[i]);
            }
            setUsers(temp);
        }
        gatherUsers();
    }, []);

    return (
        <>
            <h1>User Admin Page</h1>
            <div className="usersPageDiv">
                {users.length > 0 ? users.map(u => <User user={u} key={`User-${u.username}`} />) : null}
            </div>
        </>
    )
}

export default UsersPage;