import React, {useEffect, createContext} from "react";
import Browser from "./components/Browser";
import NavBar from "./components/NavBar";
import api from "./helpers/api";
import useSessionStorage from "./helpers/useSessionStorage";
import useLocalStorage from "./helpers/useLocalStorage";
import './css/App.css';
export const BooksContext = createContext();
export const BlogsContext = createContext();
export const NewsContext = createContext();
export const ReviewsContext = createContext();

export default function App() {
  const [bks, setBks] = useSessionStorage("books");
  const [nws, setNws] = useSessionStorage("news");
  const [rvws, setRvws] = useSessionStorage("reviews");
  const [blgs, setBlgs] = useSessionStorage("blogs");
  const [abt, setAbt] = useSessionStorage("about");
  const [races, setRaces] = useSessionStorage("races");
  const [characters, setCharacters] = useSessionStorage("characters");
  const [words, setWords] = useSessionStorage("words");
  const modalActive = useLocalStorage("modalDisplayed")[0];
  const [token, setToken] = useLocalStorage("token");
  const [isLoaded, setLoaded] = useSessionStorage("loaded", false);
  let books, news, blogs, reviews;
  
  const setSession = async () => {
    const resources = await api.homePage();
    const booksResources = resources.books;
    const blogResources = resources.blogs;
    const newsResources = resources.news;
    const reviewsResources = resources.reviews;
    const aboutResources = resources.about;
    const raceResources = resources.races;
    const characterResources = resources.characters;
    const wordResources = resources.words;
    await setBks(JSON.stringify(booksResources));
    await setBlgs(JSON.stringify(blogResources));
    await setNws(JSON.stringify(newsResources));
    await setRvws(JSON.stringify(reviewsResources));
    await setAbt(JSON.stringify([aboutResources]));
    await setRaces(JSON.stringify(raceResources));
    await setCharacters(JSON.stringify(characterResources));
    await setWords(JSON.stringify(wordResources));
      setTimeout(() => {
        const setToTrue = 
          window.sessionStorage.books === JSON.stringify(booksResources) && 
          window.sessionStorage.blogs === JSON.stringify(blogResources) && 
          window.sessionStorage.news === JSON.stringify(newsResources) && 
          window.sessionStorage.reviews === JSON.stringify(reviewsResources) && 
          window.sessionStorage.about === JSON.stringify([aboutResources]) && 
          window.sessionStorage.races === JSON.stringify(raceResources) && 
          window.sessionStorage.characters === JSON.stringify(characterResources) && 
          window.sessionStorage.words === JSON.stringify(wordResources);
        if(setToTrue){
          setLoaded(true); 
        }
      }, 1000);
    }
  useEffect(() => {
    if(!isLoaded){
      setSession();
    }
  }, []);
  
  const signout = () => {
    window.localStorage.clear()
    setToken("undefined");
  }

  if(!isLoaded){
    return <h2 className="loadingMessage">Loading...</h2>
  } else {
    return (
      <div className="App">
        <BooksContext.Provider value={books}>
          <BlogsContext.Provider value={blogs}>
            <NewsContext.Provider value={news}>
              <ReviewsContext.Provider value={reviews}>
                <NavBar />
                <Browser signout={signout} />
              </ReviewsContext.Provider>
            </NewsContext.Provider>
          </BlogsContext.Provider>
        </BooksContext.Provider>
      </div>
    );
  }
}

