import React from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import "../css/Word.css";

const Word = ({word}) => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    return (
        <div className="wordDiv">
            <div className="wordInfoDiv">
                <h2 className="wordTitle">{word.word}</h2>
                <h3 className="wordPronunciation">{word.pronounce}</h3>
                <p className="wordDefinition">{word.definition}</p>
                {word.audio ? <audio className="wordAudio" src={word.audio} controls controlsList="nodownload"></audio> : null}
            </div>
            {admin ? <div className="infoMgmtDiv wordInfoMgmtDiv">
                <div className="wordMgmtBtn">
                    <a href={`/more/update/word/${word.word}`} className="updateBtn" key="updateWordBtn">Update Word</a>
                </div>
                <div className="wordMgmtBtn">
                    <a href={`/more/delete/word/${word.word}`} className="deleteBtn" key="deleteWordBtn">Delete Word</a>
                </div>
            </div> : null}
        </div>
    )
}

export default Word;