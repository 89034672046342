import React, {useEffect, useState} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import api from "../helpers/api";
import News from "./News";
import "../css/NewsPage.css";
import useSessionStorage from "../helpers/useSessionStorage";

const NewsPage = () => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const [news, setNews] = useState([]);
    const [nws, setNws] = useSessionStorage("news");
    useEffect(() => {
        const setResources = async () => {
            if (nws !== "undefined" && nws !== undefined) {
                const storedNews = JSON.parse(nws);
                const sortedStoredNews = storedNews.sort((a, b) => new Date(b.date) - new Date(a.date));
                setNews(sortedStoredNews);
            } else {
                const n = await api.getAll("news");
                const sortedNews = n.sort((a, b) => new Date(b.date) - new Date(a.date));
                setNws(JSON.stringify(sortedNews));
                setNews(sortedNews);
            }
        };
        setResources();
    }, []);
    return (
        <>
            <h1>News</h1>
            {admin ? <p><a href="/news/new" className="newContentBtn" key="newContentBtn">Add New Article</a></p> : null}
            {news ? news.map(n => n.title !== undefined ? <News title={n.title} key={`news-${n.title}-${n.date}`} /> : null) : null}
        </>
    )
}

export default NewsPage;