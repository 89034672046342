import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "https://dragonkind-server-mhfe4.ondigitalocean.app";
// DO NOT DELETE PROD BASE URL "https://dragonkind-server-mhfe4.ondigitalocean.app";
// DO NOT DELETE UAT BASE URL "http://127.0.0.1:3001"

class AuthorApi {
    static async request(endpoint, data = {}, method = "GET") {
        const url = `${BASE_URL}/${endpoint}`;
        const token = window.localStorage.getItem("token");
        const headers = { Authorization: `Dragonian ${token}` };
        const params = method === "GET" ? data : {};

        try {
            const response = await axios({ url, data, method, params, headers });
            return response.data;
        } catch (e) {
            console.error("API Error: ", e.response);
            const message = e.response?.data?.error || "API request failed";
            throw Array.isArray(message) ? message : [message];
        }
    }

    // GET Methods
    static getAll(route) {
        return this.request(route);
    }

    static async homePage() {
        const endpoints = [
            "books",
            "blog",
            "news",
            "bookReviews",
            "about",
            "races",
            "characters",
            "pronunciation"
        ];
        const returnObj = {
            books: "",
            blogs: "",
            news: "",
            reviews: "",
            about: "",
            races: "",
            characters: "",
            words: ""
        };
        const resources = await Promise.all(endpoints.map(endpoint => this.request(endpoint)));
        [returnObj.books, returnObj.blogs, returnObj.news, returnObj.reviews, returnObj.about, returnObj.races, returnObj.characters, returnObj.words] = resources;
        return returnObj;
    }

    static getEnvVars() {
        return this.request("envVar");
    }

    static getByTitleOrUsername(route, titleOrUsername) {
        return this.request(`${route}/${titleOrUsername}`);
    }

    static getByTitleId(route, title, id) {
        return this.request(`${route}/${title}/${id}`);
    }

    static getByTitleUserId(route, title, user, id) {
        return this.request(`${route}/${title}/${user}/${id}`);
    }

    static forgotUserOrEmail(userOrEmail) {
        return this.request(`users/forgot/${userOrEmail}`);
    }

    static forgotCompare(username, answer, birthday) {
        return this.request(`users/forgot/${username}/compare`, { answer, birthday }, "POST");
    }

    // POST Methods
    static login(username, password) {
        return this.request("auth/token", { username, password }, "POST").then(result => {
            AuthorApi.token = result.token;
            return AuthorApi.token;
        });
    }

    static register(username, password, email, firstName, lastName, securityQuestion, securityAnswer, birthDate) {
        return this.request("auth/register", {
            username,
            password,
            email,
            firstName,
            lastName,
            securityQuestion,
            securityAnswer,
            birthDate
        }, "POST").then(result => {
            AuthorApi.token = result.token;
            return AuthorApi.token;
        });
    }

    static createVar(name, value) {
        return this.request("envVar", { name, value }, "POST");
    }

    static createBook(details) {
        console.log("Details", details);
        return this.request("books", details, "POST");
    }

    static createBookReview(details) {
        return this.request("bookReviews", details, "POST");
    }

    static createUserReview(details) {
        return this.request(`userReviews/${details.bookTitle}`, details, "POST");
    }

    static createWithFour(route, title, content, date, image) {
        return this.request(route, { title, content, date, image }, "POST");
    }

    static createAbout(details) {
        return this.request("about", details, "POST");
    }

    static createBlogComment(details) {
        return this.request(`blogComments/${details.blogTitle}`, details, "POST");
    }

    static createBookReviewComment(details) {
        return this.request(`bookReviewComments/${details.bookTitle}`, details, "POST");
    }

    static createCharacter(details) {
        return this.request("characters", details, "POST");
    }

    static createRace(details) {
        return this.request("races", details, "POST");
    }

    static createWord(details) {
        return this.request("pronunciation", details, "POST");
    }

    // PATCH Methods
    static updateUser(details) {
        return this.request(`users/${details.username}`, details, "PATCH");
    }

    static updateUserReview(details) {
        return this.request(`userReviews/${details.bookTitle}/${details.id}`, details, "PATCH");
    }

    static updateBookReview(details) {
        return this.request(`bookReviews/${details.originalTitle}`, details, "PATCH");
    }

    static updateBookReviewComment(details) {
        return this.request(`bookReviewComments/${details.bookTitle}/${details.username}/${details.id}`, details, "PATCH");
    }

    static updateBook(details) {
        return this.request(`books/${details.title}`, details, "PATCH");
    }

    static updateFeaturedStatus(title, featured) {
        return this.request(`books/${title}/featured`, { featured }, "PATCH");
    }

    static updateVars(name, value) {
        return this.request(`envVar/${name}`, { value }, "PATCH");
    }

    static updateByTitleOrName(route, identifier, details) {
        return this.request(`${route}/${identifier}`, details, "PATCH");
    }

    static updateAbout(origName, details) {
        return this.request(`about/${origName}`, details, "PATCH");
    }

    static updateCharacter(origName, details) {
        return this.request(`characters/${origName}`, details, "PATCH");
    }

    static updateRace(origName, details) {
        return this.request(`races/${origName}`, details, "PATCH");
    }

    static updateWord(origWord, details) {
        return this.request(`pronunciation/${origWord}`, details, "PATCH");
    }

    static updateBlogComment(details) {
        return this.request(`blogComments/${details.blogTitle}/${details.username}/${details.id}`, details, "PATCH");
    }

    static updateByTitleUsernameId(route, details) {
        return this.request(`${route}/${details.title}/${details.username}/${details.id}`, details, "PATCH");
    }

    static updateUserPassword(username, password) {
        return this.request(`users/${username}`, { password }, "PATCH");
    }

    static updateUserAdmin(username, admin) {
        return this.request(`users/${username}`, { admin }, "PATCH");
    }

    static updateUserSecurity(username, securityQuestion, securityAnswer) {
        return this.request(`users/${username}`, { securityQuestion, securityAnswer }, "PATCH");
    }

    // DELETE Methods
    static deleteVars(name) {
        return this.request(`envvars/${name}`, {}, "DELETE");
    }

    static deleteByOneIdentifier(route, identifier) {
        return this.request(`${route}/${identifier}`, {}, "DELETE");
    }

    static deleteByTwoIdentifier(route, identifierOne, identifierTwo) {
        return this.request(`${route}/${identifierOne}/${identifierTwo}`, {}, "DELETE");
    }

    static deleteByThreeIdentifiers(route, idOne, idTwo, idThree) {
        return this.request(`${route}/${idOne}/${idTwo}/${idThree}`, {}, "DELETE");
    }
}

export default AuthorApi;
