import React, { useState, useEffect } from "react";
import api from "../../helpers/api";
import useSessionStorage from "../../helpers/useSessionStorage";
import "../../css/BookReviewForm.css";

const BookReviewForm = ({ initialData = null }) => {
    const [reviews, setReviews] = useSessionStorage("reviews");
    const [formData, setFormData] = useState({
        title: "",
        series: "",
        book_number: "",
        author: "",
        releaseDate: "",
        publisher: "",
        review: "",
        contentRating: "",
        flowRating: "",
        editRating: "",
        image: "",
        book_link: "",
        youtube_link: ""
    });
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                title: initialData.title || "",
                series: initialData.series || "",
                book_number: initialData.book_number || "",
                author: initialData.author || "",
                releaseDate: initialData.releaseDate || "",
                publisher: initialData.publisher || "",
                review: initialData.review || "",
                contentRating: initialData.contentRating || "",
                flowRating: initialData.flowRating || "",
                editRating: initialData.editRating || "",
                image: initialData.image || "",
                book_link: initialData.book_link || "",
                youtube_link: initialData.youtube_link || ""
            });
            setIsUpdating(true);
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((data) => ({
            ...data,
            [name]: name === "book_number" || name.includes("Rating") ? (value ? parseInt(value, 10) : "") : value
        }));
    };    
    const getFormattedDate = () => {
        const today = new Date();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const year = today.getFullYear();
        return `${month}/${day}/${year}`;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = {
                title: formData.title.toString(),
                series: formData.series.toString(),
                book_number: formData.book_number ? parseInt(formData.book_number, 10) : 0,
                author: formData.author.toString(),
                releaseDate: formData.releaseDate ? formData.releaseDate.toString() : "",
                publisher: formData.publisher ? formData.publisher.toString() : "",
                review: formData.review.toString(),
                contentRating: formData.contentRating ? parseInt(formData.contentRating, 10) : initialData.content_rating,
                flowRating: formData.flowRating ? parseInt(formData.flowRating, 10) : initialData.flow_rating,
                editRating: formData.editRating ? parseInt(formData.editRating, 10) : initialData.edit_rating,
                image: formData.image ? formData.image.toString() : "",
                book_link: formData.book_link ? formData.book_link.toString() : "",
                youtube_link: formData.youtube_link ? formData.youtube_link.toString() : "",
                date: !initialData ? getFormattedDate() : initialData.date
            };
    
            if (isUpdating) {
                await api.updateByTitleOrName("bookReviews", initialData.title, dataToSubmit);
            } else {
                await api.createWithFour("bookReviews", dataToSubmit);
            }
            window.sessionStorage.removeItem("reviews");
            const updatedReviews = await api.getAll("bookReviews");
            setReviews(JSON.stringify(updatedReviews));
            window.location.href = "/reviews";
        } catch (error) {
            console.error("API Request Failed: ", error);
        }
    };
    
    

    return (
        <div className="bookReviewFormParent">
            <h2>{isUpdating ? `Update Book Review for ${initialData.title}` : "Add New Book Review"}</h2>
            <form className="bookReviewForm" onSubmit={handleSubmit}>
                <div className="bookReviewFormDiv">
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewTitle"
                            placeholder="Title"
                            name="title"
                            id="title"
                            required={!isUpdating}
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewSeries"
                            placeholder="Series"
                            name="series"
                            id="series"
                            value={formData.series}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            className="formInputs bookReviewBookNumber"
                            placeholder="Book Number"
                            name="book_number"
                            id="book_number"
                            value={formData.book_number}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewAuthor"
                            placeholder="Author"
                            name="author"
                            id="author"
                            required={!isUpdating}
                            value={formData.author}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewRelease"
                            placeholder="Release Date"
                            name="releaseDate"
                            id="releaseDate"
                            required={!isUpdating}
                            value={formData.releaseDate}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewPublisher"
                            placeholder="Publisher"
                            name="publisher"
                            id="publisher"
                            value={formData.publisher}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea bookReviewReview"
                            placeholder="Review"
                            name="review"
                            id="review"
                            required={!isUpdating}
                            value={formData.review}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewImage"
                            placeholder="Image Link"
                            name="image"
                            id="image"
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewBookLink"
                            placeholder="Book Link"
                            name="book_link"
                            id="book_link"
                            value={formData.book_link}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            className="formInputs bookReviewYoutubeLink"
                            placeholder="YouTube Link"
                            name="youtube_link"
                            id="youtube_link"
                            value={formData.youtube_link}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="1"
                            className="formRating bookReviewRating"
                            placeholder="Content Rating"
                            min="1"
                            max="5"
                            name="contentRating"
                            id="contentRating"
                            required={!isUpdating}
                            value={formData.contentRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="1"                            
                            className="formRating bookReviewRating"
                            placeholder="Flow Rating"
                            min="1"
                            max="5"
                            name="flowRating"
                            id="flowRating"
                            required={!isUpdating}
                            value={formData.flowRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="number"
                            step="1"                            
                            className="formRating bookReviewRating"
                            placeholder="Edit Rating"
                            min="1"
                            max="5"
                            name="editRating"
                            id="editRating"
                            required={!isUpdating}
                            value={formData.editRating}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BookReviewForm;
