import { useState } from "react";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";

const BookDelete = () => {
    const [books, setBooks] = useSessionStorage("books");
    const [isSet, setIsSet] = useState(false);
    const url = window.location.href.split("/");
    const title = url[url.length-1].replaceAll("%20", " ");
    if(!isSet){
        const deleteBook = async (title) => {
            await api.deleteByOneIdentifier("books", title);
        }
        deleteBook(title); 
        window.sessionStorage.removeItem("books");
        const updateBooks = async () =>{
            let temp = [];
            const bkRes = await api.getAll("books");
            for(let i = 0; i < bkRes.length; i++){
                temp.push(bkRes[i]);
            }
            await setBooks(JSON.stringify(temp));
            setIsSet(true);
        }
        updateBooks();
    } else {
        window.location.href="/books";
    }
}

export default BookDelete