import React, {useEffect, useState} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import api from "../helpers/api";
import Review from "./Review";
import "../css/ReviewsPage.css"
import useSessionStorage from "../helpers/useSessionStorage";

const ReviewsPage = () => {
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const [reviews, setReviews] = useState([]);
    const [rvws, setRvws] = useSessionStorage("reviews");
    const setResources = async () => {
        const temp = [];
        if(rvws !== "undefined"){
            setReviews(JSON.parse(rvws));
        } else {
            const rl = await api.getAll("bookReviews");
            for(let i = 0; i < rl.length; i++){
                temp.push(rl[i]);
            }
            const sortedTemp = temp.sort((a, b) => (a.date > b.date) ? 1 : (a.date < b.date) ? -1 : 0);
            setRvws(JSON.stringify(sortedTemp));
            setReviews(JSON.parse(rvws));
        }
    }
    useEffect(() => {
        setResources();
    }, []);
    return(
        <>
            <h1>Book Reviews by Author Kenneth L Powell</h1>
            {admin ? <p><a href="/reviews/new" className="newContentBtn" key="newContentBtn">Add New Book Review</a></p> : null}
            {reviews ? reviews.map(r => r.title !== undefined ? <Review title={r.title} key={`review-${r.title}-${r.date}`} /> : null) : null}
        </>
    )
}

export default ReviewsPage;