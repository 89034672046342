import React, {useState, useContext, useEffect} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import useSessionStorage from "../helpers/useSessionStorage";
import api from "../helpers/api";
import Race from "./Race";
import Character from "./Character";
import Word from "./Word";
import "../css/MorePage.css"

const MorePage = () => {
    const [raceIsActive, setRacesActive] = useState(false);
    const [charactersAreActive, setCharactersActive] = useState(false);
    const [wordsAreActive, setWordsActive] = useState(false);
    const [races, setRaces] = useState([]);
    const [characters, setCharacters] = useState ([]);
    const [words, setWords] = useState([]);
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const [c, setC] = useSessionStorage("characters");
    const [r, setR] = useSessionStorage("races");
    const [w, setW] = useSessionStorage("words");

    useEffect(() => {
        const setResources = async () =>{
            const rTemp = [];
            const cTemp = [];
            const wTemp = [];
            //************* Characters *************
            if(c !== "undefined"){
                const temp = JSON.parse(c)
                for(let ch of temp){
                    cTemp.push(ch);
                }
                setCharacters(cTemp);
            } else{
                const ch = await api.getAll("characters");
                for(let i = 0; i < ch.length; i++){
                    cTemp.push(ch[i]);
                }
                setCharacters(cTemp);
                setC(JSON.stringify(ch));
            }
            //************* Races *************
            if(r !== "undefined"){
                const temp = JSON.parse(r)
                for(let ra of temp){
                    rTemp.push(ra);
                }
                setRaces(rTemp);
            } else{
                const rac = await api.getAll("races");
                for(let i = 0; i < rac.length; i++){
                    rTemp.push(rac[i]);
                }
                setRaces(rTemp);
                setR(JSON.stringify(rac));
            }
            //************* Words *************
            if(w !== "undefined"){
                const temp = JSON.parse(w)
                for(let wo of temp){
                    wTemp.push(wo);
                }
                setWords(wTemp);
            } else{
                const wor = await api.getAll("pronunciation");
                for(let i = 0; i < wor.length; i++){
                    wTemp.push(wor[i]);
                }
                setWords(wTemp);
                setW(JSON.stringify(wor));
            }            
        }
        setResources();
    }, [])
    return(
        <div className="morePageDiv">
            <div className="racesDiv">
                <h3 className="sectionHeader">Races {races.length > 0 ? <button id="raceListShowBtn" className="showHideBtn sectionShowHideBtn" onClick={() => setRacesActive(current => !current)}>{raceIsActive ? "Hide" : "Show"}</button> : null}</h3>
                {admin ? <p className="sectionAddBtn"><a href="/more/new/race" className={`newContentBtn ${raceIsActive || races.length <= 0 ? "":"hidden"}`} key="newContentBtn">Add Race</a></p> : null}
                <div className={`races ${raceIsActive ? "" : "hidden"}`}>
                    {races ? races.map(r => r.name !== undefined ? <Race race={r} key={`${r.name}`} /> : null) : null}                
                </div>
            </div>
            <div className="charactersDiv">
                <h3 className="sectionHeader">Characters {characters.length > 0 ? <button id="characterListShowBtn" className="showHideBtn sectionShowHideBtn" onClick={() => setCharactersActive(current => !current)}>{charactersAreActive ? "Hide" : "Show"}</button> : null}</h3>
                {admin ? <p className="sectionAddBtn"><a href="/more/new/character" className={`newContentBtn ${charactersAreActive || characters.length <= 0 ? "":"hidden"}`} key="newContentBtn">Add Character</a></p> : null}
                <div className={`characters ${charactersAreActive ? "" : "hidden"}`}>
                    {characters ? characters.map(c => c.name !== undefined ? <Character character={c} key={`${c.name}`} /> : null) : null}                
                </div>
            </div>
            <div className="wordsDiv">
                <h3 className="sectionHeader">Dictionary and Pronunciation Guide {words.length > 0 ? <button id="wordListShowBtn" className="showHideBtn sectionShowHideBtn" onClick={() => setWordsActive(current => !current)}>{wordsAreActive ? "Hide" : "Show"}</button> : null}</h3>                
                {admin ? <p className="sectionAddBtn"><a href="/more/new/word" className={`newContentBtn ${wordsAreActive || words.length <= 0 ? "":"hidden"}`} key="newContentBtn">Add Word</a></p> : null}
                <div className={`words ${wordsAreActive ? "" : "hidden"}`}>
                    {words ? words.map(w => w.word !== undefined ? <Word word={w} key={`${w.word}`} /> : null) : null}
                </div>
            </div>
        </div>
    );
}

export default MorePage;