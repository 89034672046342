import React, { useState } from "react";
import api from "../../helpers/api";
import "../../css/ForgotForm.css"

const ForgotForm = () => {
    const [user, setUser] = useState({});
    const [isActiveA, setIsActiveA] = useState(true);
    const [isActiveB, setIsActiveB] = useState(false);
    const [isActiveC, setIsActiveC] = useState(false);
    const INITIAL_STATE = {
        userOrEmail: "",
        answer: "",
        birthday: "",
        firstPassword: "",
        secondPassword: ""
    }
    const [formData, setFormData] = useState(INITIAL_STATE)

    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmitA = async (e) => {
        e.preventDefault();
        let u = await api.forgotUserOrEmail(formData.userOrEmail);
        setUser(u);
        setIsActiveA(current => !current);
        setIsActiveB(current => !current);
    }
    const handleSubmitB = async (e) => {
        e.preventDefault();
        const match = await api.forgotCompare(user.username, formData.answer, formData.birthday);
        if(match.status === true){
            setIsActiveB(current => !current);
            setIsActiveC(current => !current);
        } else {
            //Setting error message to incorrect Security Answer or Birthdate (Error Message hasn't been added yet.)
        }
        
    }
    const handleSubmitC = async (e) => {
        e.preventDefault();
        if(formData.firstPassword === formData.secondPassword){
            //Ensure Error message is hidden.
            const successful = await api.updateUserPassword(user.username, formData.secondPassword);
            if(successful){
                //Toggle success message.
                window.location.href="/login";
            }
        } else {
            //Toggle error message with Mismatch message.
        }
        setIsActiveC(current => !current);
    }

    return (
        <div className="forgotDiv">
            <div id="forgotFormA" className={`forgotForm ${isActiveA ? "" : "hidden"}`}>
                <p className="forgotP">Enter your username or email below.</p>
                <form onSubmit={handleSubmitA}>
                    <div>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Enter Username or Email"
                            name="userOrEmail"
                            id="userOrEmail"
                            key="userOrEmail"
                            value={formData.userOrEmail}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="forgotFormBtnDiv">
                        <button className="forgotFormBtn submitBtn">Submit</button>
                    </div>
                </form>
            </div>
            <div id="forgotFormB" className={`forgotForm ${isActiveB ? "" : "hidden"}`}>                        
                <p className="formBP">Answer your question and enter your birthdate</p>
                <form onSubmit={handleSubmitB}>
                    <div>            
                        <p className="questionP">{user.user ? user.user.securityQuestion:null}</p>
                        <p id="wrongAnswer" className="hidden">Sorry that was incorrect.</p>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Answer your question"
                            required
                            name="answer"
                            id="answer"
                            key="answer"
                            value={formData.answer}
                            onChange={handleChange}
                        />
                    </div>
                    <div>            
                        <p className="birthdayP">Enter your Birthdate in the format mm/dd/yyyy</p>
                        <p id="wrongBirthday" className="hidden">Sorry that was incorrect.</p>
                        <input
                            type="text"
                            className="formInputs"
                            placeholder="Enter Birthdate"
                            required
                            name="birthday"
                            id="birthday"
                            key="birthday"
                            value={formData.birthday}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="forgotFormBtnDiv">
                        <button className="forgotFormBtn submitBtn">Submit</button>
                    </div>
                </form>
            </div>
            <div id="forgotFormC" className={`forgotForm ${isActiveC ? "" : "hidden"}`} onSubmit={handleSubmitC}>                        
                <p className="formCP">Enter your new password twice.</p>
                <form onSubmit={handleSubmitC}>
                    <div>            
                        <p className="firstPassword">Enter your new Password</p>
                        <input
                            type="password"
                            className="formInputs"
                            placeholder="Enter your password"
                            required
                            name="firstPassword"
                            id="firstPassword"
                            key="firstPassword"
                            minLength="12"
                            value={formData.firstPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div>            
                        <p className="firstPassword">Confirm your new Password matches</p>
                        <p id="passwordMatch" className="hidden">Sorry your passwords don't match.</p>
                        <input
                            type="password"
                            className="formInputs"
                            placeholder="Confirm Password Match"
                            required
                            name="secondPassword"
                            id="secondPassword"
                            key="secondPassword"
                            minLength="12"
                            value={formData.secondPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="forgotFormBtnDiv">
                        <button className="forgotFormBtn submitBtn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgotForm;