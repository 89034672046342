import React, {useEffect, useState} from "react";
import jwt from "jwt-decode";
import useLocalStorage from "../../helpers/useLocalStorage";
import api from "../../helpers/api";
import "../../css/BlogCommentUpdateForm.css";

const BlogCommentUpdateForm = () => {
    const [info, setInfo] = useState({});
    const href = window.location.href.split("/");
    const id = href[href.length-1];
    const title = href[href.length-2];
    const token = useLocalStorage("token")[0];
    const username = token !== "undefined" ? jwt(token).username : null;

    useEffect(() => {
        const gatherComment = async (id, username, title) => {
            const res = await api.getByTitleUserId("blogComments", title, username, id);

            setInfo(res);
        }
        gatherComment(id, username, title);
    }, [id, title, username]);
    const INITIAL_STATE = {
        comment:""
    }
    const [formData, setFormData] = useState(INITIAL_STATE);
    const handleChange = e => {
        const {name, value} = e.target;
        setFormData(data => ({
            ...data,
            [name]:value
        }));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const comment = formData.comment !== "" ? formData.comment : info.comment;
        api.updateBlogComment(info.id, info.username, info.title, comment, info.date);
        setFormData(INITIAL_STATE);
        window.location.href = `/blog/${title}`;
    }
    return(
        <div className="updateBlogCommentParent">
            <h2>Update Blog Comment by {info.username}</h2>
            <form className="updateBlogCommentForm" onSubmit={handleSubmit}>
                <div className="updateBlogCommentDiv">
                    <div>
                        <textarea
                            rows="20"
                            cols="100"
                            className="formTextArea blogCommentComment"
                            placeholder="Comment"
                            name="comment"
                            id="comment"
                            key="comment"
                            value={formData.comment}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <button className="submitBtn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BlogCommentUpdateForm;