import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import jwt from "jwt-decode";
import useLocalStorage from "../helpers/useLocalStorage";
import useSessionStorage from "../helpers/useSessionStorage";
import logo from "../images/Logo.png";
import "../css/NavBar.css";

const NavBar = () => {
    const [page, setPage] = useState("");
    const [isActive, setIsActive] = useState(false);
    const bks = useSessionStorage("books"); 
    const books = () => {
        if(bks[0] === undefined) return [];
        else if(bks[0] === "undefined") return [];
        else return JSON.parse(bks[0]);
    }
    const blgs = useSessionStorage("blogs");
    const blogs = () => {
        if(blgs[0] === undefined) return [];
        else if(blgs[0] === "undefined") return [];
        else return JSON.parse(blgs[0]);
    }
    const nws = useSessionStorage("news");
    const news = () => {
        if(nws[0] === undefined) return [];
        else if(nws[0] === "undefined") return [];
        else return JSON.parse(nws[0]);
    }
    const rvws = useSessionStorage("reviews");
    const reviews = () => {
        if(rvws[0] === undefined) return [];
        else if(rvws[0] === "undefined") return [];
        else return JSON.parse(rvws[0]);
    }
    const token = useLocalStorage("token")[0];
    const admin = token !== "undefined" ? jwt(token).admin : 0;
    const username = token !== "undefined" ? jwt(token).username : null;
    const whatPage = () => {
        let p = window.location.href;
        setPage(p);
    }
    useEffect(() => {
        whatPage();
    }, [page]);
   window.addEventListener("resize", (e) => {
        if(window.innerWidth >= 1050){
            setIsActive(false)
        } else setIsActive(true)
   });

    return (
        <div className="container">
            <div className="logoDiv">
                <img className="logoImg" src={logo} />
            </div>
            <div className="hamburgerBtnDiv">
                <button className="hamburgerBtn" onClick={() => setIsActive(current => !current)}><FontAwesomeIcon className="hamburgerBtnIcon" icon={faBars} /></button>
            </div>
            <div className={`navContainer ${isActive  ? "hidden" : isActive && window.innerWidth >= 1050 ? "" : ""}`}>
                <div className="rotated">
                    <a href="/" id="homeBtn" className={`navButton ${page[page.length-1] === "/" ? "selected":"unselected"}`}>Home</a>
                </div>
                {admin || books().length > 0 ? <div className="rotated">
                    <a href="/books" className={`navButton ${page.includes("/books") ? "selected":"unselected"}`}>Books</a>
                </div> : null }
                {admin || news().length > 0 ? <div className="rotated">
                    <a href="/news" className={`navButton ${page.includes("/news") ? "selected":"unselected"}`}>News</a>
                </div> : null }
                {admin || blogs().length > 0 ? <div className="rotated">
                    <a href="/blog" className={`navButton ${page.includes("/blog") ? "selected":"unselected"}`}>Blogs</a>
                </div> : null }
                {admin || reviews().length > 0 ? <div className="rotated">
                    <a href="/reviews" className={`navButton ${page.includes("/reviews") ? "selected":"unselected"}`}>Reviews</a>
                </div> : null }
                <div className="rotated">
                    <a href="/about" className={`navButton ${page.includes("/about") ? "selected" : "unselected"}`}>About</a>
                </div>
                <div className="rotated">
                    <a href="/more" className={`navButton ${page.includes("/more") ? "selected" : "unselected"}`}>More</a>
                </div>
                <div className="rotated">
                    <a href="/contact" id="contactButton" className={`navButton ${page.includes("/contact")  ? "selected" : "unselected"}`}>Contact and Social</a>
                </div>
                {admin ? <div className="rotated">
                    <a href="/users" id="usersBtn" className={`navButton ${page.includes(`/users`) ? "selected" : "unselected"}`}>User Admin</a>
                </div> : null}
                {username !== null ? 
                    <p className="un usernameIncluded">
                        <a className={`userPageBtn ${page.includes(`${username}`) ? "deactiveLink" : ""}`} href={`/users/${username}`}>{username}</a>
                        <a href="/signout" className="signOutBtn">Signout</a></p> : 
                    <p className="un"><a href="/login" className="loginBtn">Login</a></p>}
            </div>
        </div>
    )
}

export default NavBar;